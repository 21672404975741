import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import { addSnackbar } from '../../../../redux/snackbars'
import createByAdmin from '../../../../api/transactions/createByAdmin'

export default function AdminNewTransaction() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = React.useState('')

  const [productUuid, setProductUuid] = React.useState('')
  const [userUuid, setUserUuid] = React.useState('')

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const handleSave = () => {
    setSaving(true)

    createByAdmin({
      productUuid,
      userUuid
    }).then(() => {
      setSaving(false)
      dispatch(addSnackbar({id: 'admintransactioncreated', text: 'Transaction created.'}))
      navigate('/app/admin/transactions')
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/admin/transactions')}} underline="hover" color="inherit" href="/app/admin/transactions">
            Transactions
          </Link>

          <Typography color="text.primary">
            New Transaction
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="sm" sx={{pt: 3}}>
        <FormGroup>
          <Box component="form" noValidate>
            <Grid sx={{ mt: 2 }}>
              <TextField
                name="userUuid"
                required
                fullWidth
                id="userUuid"
                value={userUuid || ''}
                label="User UUID"
                onChange={e => {setDirty(true); setUserUuid(e.target.value)}}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <TextField
                name="productUuid"
                required
                fullWidth
                id="productUuid"
                value={productUuid || ''}
                label="Product UUID"
                onChange={e => {setDirty(true); setProductUuid(e.target.value)}}
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Button fullWidth imgSrc onClick={handleSave} disabled={!isDirty || isSaving} variant="contained">Save</Button>
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
