import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import createUserPaymentMethod from '../../api/userPaymentMethods/createUserPaymentMethod'

import { addSnackbar } from '../../redux/snackbars'

export default function NewUserPaymentMethod({
  setupIntent,
  onCreate
}) {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [errorMessage, setErrorMessage] = React.useState('')

  const [readyToSubmit, setReadyToSubmit] = React.useState(false)
  const [stripeCardElementError, setstripeCardElementError] = React.useState('')
  const [isSaving, setSaving] = React.useState(false)

  const handleSave = async () => {
    if (elements == null) return

    setSaving(true)

    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })

    if (error) {
      console.error(error)
      setErrorMessage((error.response && error.response.data && error.response.data.error) || error.message)
      setSaving(false)
      return
    }

    try {
      await createUserPaymentMethod(currentUserProfile.uuid, paymentMethod)
    } catch(error) {
      console.error(error)
      setErrorMessage((error.response && error.response.data && error.response.data.error) || error.message)
      setSaving(false)
      return
    }

    onCreate()
    setSaving(false)
    dispatch(addSnackbar({id: 'cardcreated', text: 'Card created.'}))
  }

  return (
    <Paper sx={{p: 3, mb: 3}}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1, mb: 3 }}
      >
        Create Payment Method
      </Typography>

      {!setupIntent &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {setupIntent &&
      <Box component="form" noValidate>
        {(errorMessage || (stripeCardElementError && stripeCardElementError.message)) &&
        <Stack sx={{ width: '100%', mb: 3 }} spacing={2}>
          <Alert severity="error">{errorMessage || (stripeCardElementError && stripeCardElementError.message)}</Alert>
        </Stack>}

        <CardElement
          onChange={e => {setReadyToSubmit(e.complete); setstripeCardElementError(e.error || '')}}
        />

        <Grid sx={{ mt: 4 }}>
          <LoadingButton onClick={handleSave} disabled={!readyToSubmit} loading={isSaving} color='info' variant="contained">Save</LoadingButton>
        </Grid>
      </Box>}
    </Paper>
  )
}
