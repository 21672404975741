import * as React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'

import fetchModelRun from '../../../../api/modelRuns/get'
import { addSnackbar } from '../../../../redux/snackbars'
import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

export default function ShowModelRun() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { modelRunUuid } = useParams()

  const [errorMessage, setErrorMessage] = React.useState('')
  const [modelRun, setModelRun] = React.useState(null)
  const [modelRunLoading, setModelRunLoading] = React.useState(true)

  React.useEffect(() => {
    setModelRunLoading(true)
    fetchModelRun(modelRunUuid).then(modelRun => {
      setModelRunLoading(false)
      setModelRun(modelRun)
    }).catch(err => {
      console.error(err)
      setModelRunLoading(false)
      setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingmodelRun', text: 'Error fetching modelRun.'}))
    })
  }, [modelRunUuid, dispatch])

  const hasChance = modelRun && (modelRun.chance === 0 || modelRun.chance > 0)

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/admin/model-runs')}} underline="hover" color="inherit" href="/app/admin/model-runs">
            Model Runs
          </Link>

          {/* <Typography color="text.primary">
            {modelRun &&
            <Link onClick={e => {e.preventDefault(); navigate(`/app/admin/model-runs/${modelRun.uuid}`)}} underline="hover" color="inherit" href={`/app/admin/model-runs/${modelRun.uuid}`}>
              {modelRun.uuid}
            </Link>}
            {!modelRunLoading && !modelRun && 'Unknown'}
            {modelRunLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
          </Typography> */}
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />


      <Container sx={{pt: 3}} maxWidth="md">
        <Typography component="h1" variant="h3">
          Model Run
        </Typography>

        {hasChance &&
        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Chance
          </Typography>
          <Typography>
            {modelRun && `${modelRun.chance}%`}
          </Typography>
        </Box>}

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Algorithm
          </Typography>
          <Typography>
            {modelRunLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {modelRun && (modelRun.algorithm && modelRun.algorithm.name)}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Created By
          </Typography>
          <Typography>
            {modelRunLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {modelRun && ((modelRun.user && modelRun.user.email) || modelRun.browserFingerprint || 'Unknown')}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Created At
          </Typography>
          <Typography>
            {modelRunLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {modelRun && new moment(modelRun.createdAt).format('llll')}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Image
          </Typography>
          {modelRunLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}

          {modelRun && modelRun.imageURL &&
          <Box display='flex' flexDirection="column" alignItems='center' maxWidth='600px'>
            <img
              src={modelRun.imageURL}
              height="auto"
              width="auto"
              alt={`Model run output with chance ${modelRun.chance}`}
              style={{maxWidth: '100%', maxHeight: '600px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
            />
          </Box>}
        </Box>

        {modelRun && modelRun.inputText &&
        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Input Text
          </Typography>
          <Typography>
            {modelRun.inputText}
          </Typography>
        </Box>}

        {modelRun && modelRun.outputText &&
        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Output Text
          </Typography>
          <Typography style={{whiteSpace: 'pre-wrap'}}>
            {modelRun.outputText}
          </Typography>
        </Box>}

      </Container>

      <Copyright sx={{ pt: 4 }} />
    </Box>
  )
}
