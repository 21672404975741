import { createSlice } from '@reduxjs/toolkit'

export const currentUserProfileSlice = createSlice({
  name: 'currentUserProfile',
  initialState: null,
  reducers: {
    setCurrentUserProfile: (state, action) => {
      return action.payload
    },
  },
})

export const { setCurrentUserProfile } = currentUserProfileSlice.actions

export default currentUserProfileSlice.reducer
