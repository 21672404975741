import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import PersonIcon from '@mui/icons-material/Person'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import logout from '../../api/auth/logout'
import { setCurrentUserProfile } from '../../redux/currentUserProfile'
import { addSnackbar } from '../../redux/snackbars'

import mdTheme from '../../themes/iipTheme'

export default function AppBarItems(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogoutClick = () => {
    logout()
      .then(() => {
        handleClose()
        dispatch(setCurrentUserProfile(null))
        dispatch(addSnackbar({id: 'logoutsuccess', text: 'Log out successful.'}))
        navigate("/app")
      })
      .catch(err => {
        console.error('Log out failed', err)
        dispatch(addSnackbar({id: 'logoutsuccess', text: 'Log out failed.'}))
        handleClose()
        dispatch(setCurrentUserProfile(null))
        navigate("/app")
      })
  }

  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  return (
    <ThemeProvider theme={mdTheme}>
      {currentUserProfile &&
      <IconButton
        onClick={e => handleClick(e)}
        color="inherit"
      >
        <Badge color="primary">
          <PersonIcon color="primary.contrastText" />
        </Badge>
      </IconButton>}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={() => navigate('/app')}>Home</MenuItem> */}
        <MenuItem onClick={() => navigate('/app/algorithms')}>Algorithms</MenuItem>
        {currentUserProfile && currentUserProfile.isAdmin &&
        <MenuItem onClick={() => navigate('/app/admin')}>Admin</MenuItem>}
        {currentUserProfile &&
        <MenuItem onClick={() => navigate('/app/account')}>Account</MenuItem>}
        {currentUserProfile &&
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem> }
      </Menu>

      {!currentUserProfile &&
      <Button color="inherit" onClick={() => navigate('/app/signin')}>Sign In</Button>}

      {!currentUserProfile &&
      <Button color="inherit" onClick={() => navigate('/app/signup')}>Sign Up</Button>}
    </ThemeProvider>
  );
}
