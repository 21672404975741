import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'

import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import confirmPaymentIntentForTransaction from '../../../api/transactions/confirm'

import { addSnackbar } from '../../../redux/snackbars'

export default function CompleteTransactionButton({
  transaction,
  setErrorMessage,
}) {
  const stripe = useStripe()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    paymentIntent
  } = transaction

  const [loading, setLoading] = React.useState(false)

  console.log('Stripe payment intent has status', transaction.paymentIntent.status, transaction.paymentIntent)

  if (!paymentIntent) return <div>Failed to find payment associated with transaction.</div>

  const confirmPayment = async () => {
    setLoading(true)

    const redirectOnSuccess = () => {
      dispatch(addSnackbar({id: 'transactionsuccess', text: 'Payment successful.'}))
      navigate(`/app/account/transactions/${transaction.uuid}`)
    }

    const confirmTransaction = () => {
      // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent
      return confirmPaymentIntentForTransaction(transaction.uuid).then(newPaymentIntent => {
        if (newPaymentIntent.status === 'succeeded')
          return redirectOnSuccess()

          confirmPayment()
      }).catch(err => {
        console.error('error in confirmPaymentIntentForTransaction', err)
        setLoading(false)
        return setErrorMessage('Failed to confirm payment complete.')
      })
    }

    try {
      switch (paymentIntent.status) {
        case "requires_action":
        case "requires_source_action":
          // Card requires authentication
          const handleCardActionResult = await stripe.handleCardAction(paymentIntent.client_secret)

          if (handleCardActionResult.error) {
            console.error('handleCardActionResult error', handleCardActionResult)
            setLoading(false)
            return setErrorMessage('Your card was denied, please provide a new payment method.')
          } else if (handleCardActionResult.paymentIntent.status === "requires_confirmation") {
            return confirmTransaction()
          }

        case "requires_payment_method":
        case "requires_source":
          // Card was not properly authenticated, suggest a new payment method
          setLoading(false)
          return setErrorMessage(`Your card was denied, please provide a new payment method (${paymentIntent.status}).`)
        case "requires_confirmation":
          return confirmTransaction()
        case "succeeded":
          console.log('Stripe payment intent had status succeeded, redirecting', paymentIntent)
          return redirectOnSuccess()
        default:
          setLoading(false)
          return setErrorMessage('Stripe returned invalid status.')
      }
    } catch(err) {
      setLoading(false)
      setErrorMessage(err.message || 'Error finalising payment.')
    }
  }

  return (
    <Box sx={{mt: 3}}>
      <LoadingButton
        variant="contained"
        onClick={() => confirmPayment()}
        fullWidth
        loading={loading}
      >
        <ShoppingCartIcon sx={{mr: 1}} /> Complete Checkout
      </LoadingButton>
    </Box>
  )
}
