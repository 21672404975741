import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchAlgorithms({name, showHidden}={}) {
  const params = new URLSearchParams()

  if (name) params.set('name', name)
  if (showHidden) params.set('showHidden', true)

  return axios.get(`${API_BASE_URL}/algorithms?${params.toString()}`).then(response => (response.data))
}
