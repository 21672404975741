import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

export default function PaperContainer({
  background,
  children
}) {
  return(
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{p: 1}}
      >
        <Card
          sx={{
            background,
            p: 5,
            pt: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {children}
        </Card>
      </Grid>
    </Grid>
  )
}
