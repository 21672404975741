import * as React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import FilterList from '@mui/icons-material/FilterList'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import TextField from '@mui/material/TextField'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'
import FilterContainer from '../../../../components/FilterContainer'

import searchUsers from '../../../../api/users/search'

let queryTimeout

const PER_PAGE = 10

export default function AdminUserManagement() {
  const navigate = useNavigate()

  const [usersLoading, setUsersLoading] = React.useState(true)
  const [users, setUsers] = React.useState(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')

  const handleSearchUsers = () => {
    searchUsers({
      email,
      name,
      page,
      perPage: PER_PAGE
    }).then(({
      users,
      totalPages
    }) => {
      setUsers(users)
      setTotalPages(totalPages)
      setUsersLoading(false)
    }).catch(err => {
      console.error(err)
      setUsersLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    setUsersLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchUsers()
    }, 300)
  }, [name, email, page])

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Users
        </Typography>
      </HeadingContainer>

      <Divider />

      <FilterContainer>
        <FilterList sx={{ fontSize: 22 }} />
        <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>

        <TextField
          sx={{pr: 1}}
          size="small"
          value={email || ''}
          onChange={e => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        />

        <TextField
          sx={{pr: 1}}
          size="small"
          value={name || ''}
          onChange={e => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />
      </FilterContainer>

      <Divider sx={{mb: 2}} />

      {usersLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!usersLoading &&
      <Container maxWidth={false}>
        <Pagination
          sx={{mb: 3}}
          count={totalPages}
          page={page}
          onChange={(_, v) => setPage(v)}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Created At</b></TableCell>
                <TableCell align="left"><b>Email</b></TableCell>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="left"><b>Email Confirmed</b></TableCell>
                <TableCell align="left"><b>Admin</b></TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.map((user) => (
                <TableRow
                  key={user.email}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{new moment(user.createdAt).format('llll')}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.firstname} {user.lastname}</TableCell>
                  <TableCell align="left">{user.emailConfirmed ? 'True' : 'False'}</TableCell>
                  <TableCell align="left">{user.isAdmin ? 'True' : 'False'}</TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => navigate(`/app/admin/users/${user.uuid}`)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
