import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchProducts({name, purchasable}={}) {
  const params = new URLSearchParams()

  if (name) params.set('name', name)
  if (purchasable === true || purchasable === false) params.set('purchasable', purchasable)

  return axios.get(`${API_BASE_URL}/products?${params.toString()}`).then(response => (response.data))
}
