import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import fetchMe from '../../../../api/users/fetchMe'
import updateUser from '../../../../api/users/update'

import { addSnackbar } from '../../../../redux/snackbars'

import { setCurrentUserProfile } from '../../../../redux/currentUserProfile'

export default function Profile() {
  const dispatch = useDispatch()
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [userLoading, setUserLoading] = React.useState(true)
  const [user, setUser] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const handleReceiveUserProfile = (user) => {
    setUser(user)
    setFirstname(user.firstname)
    setLastname(user.lastname)
  }

  React.useEffect(() => {
    if (!currentUserProfile) return
    fetchMe(currentUserProfile.uuid).then(user => {
      setUserLoading(false)
      handleReceiveUserProfile(user)
    }).catch(err => {
      console.error(err)
      setUserLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }, [currentUserProfile])

  const handleSave = () => {
    setSaving(true)
    updateUser(currentUserProfile.uuid, {
      firstname,
      lastname
    }).then(user => {
      handleReceiveUserProfile(user)
      setSaving(false)
      dispatch(addSnackbar({id: 'adminuserupdated', text: 'User updated.'}))
      dispatch(setCurrentUserProfile(user))
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          My Profile
        </Typography>
      </HeadingContainer>

      <Divider sx={{mb: 2}} />

      {userLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!userLoading &&
      <Container maxWidth="sm">
        <FormGroup>
          <Box component="form" noValidate>
            <Grid sx={{ mt: 2 }}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                value={firstname || ''}
                label="First Name"
                onChange={e => {setDirty(true); setFirstname(e.target.value)}}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <TextField
                autoComplete="family-name"
                name="lastName"
                required
                fullWidth
                id="lastName"
                value={lastname || ''}
                label="Last Name"
                onChange={e => {setDirty(true); setLastname(e.target.value)}}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <LoadingButton
                fullWidth
                onClick={handleSave}
                disabled={!isDirty || !user}
                loading={isSaving || userLoading}
                variant="contained"
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </FormGroup>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
