import { useNavigate } from 'react-router'

import Link from '@mui/material/Link'

export default function RouterLink({
  children,
  underline,
  disableOnClick,
  textAlign,
  color,
  href,
  sx
}) {
  const navigate = useNavigate()

  return (
    <Link
      sx={sx}
      textAlign={textAlign}
      onClick={disableOnClick ? undefined : (e => {e.preventDefault(); href && navigate(href)})}
      underline={underline}
      color={color}
      href={href}
    >
      {children}
    </Link>
  );
}
