import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export default function Copyright(props) {
  return (
    <Typography variant="body2" color={props.color || "primary"} align="center" {...props}>
      {'Copyright © '}
      Is It Porn?
      {/* <Link color="inherit" href="https://alexanderpaterson.com">
      </Link> */}
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
