import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import Alert from '@mui/material/Alert'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import mdTheme from '../../themes/iipTheme'
import IsItPornButton from '../../components/IsItPornButton'
import ImpactText from '../../components/ImpactText'

import getMyCredits from '../../api/credits/mine'
import { createModelRunFormData } from '../../api/modelRuns/create'
import {addSnackbar} from '../../redux/snackbars'
import HeroForAlgorithm from '../../components/HeroForAlgorithm'
import PaperContainer from '../../components/PaperContainer'
import generateCreditMessage from '../../helpers/credits/generateCreditMessage'
import { ALGORITHM_SKU_ISITPORN } from '../../consts'
import DescriptionBoxForAlgorithm from '../../components/DescriptionBoxForAlgorithm'

const {
  loadImage
} = window

export default function IsItPorn() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [creditsLoading, setCreditsLoading] = React.useState(true)
  const [remainingCredits, setRemainingCredits] = React.useState(0)
  const [totalFreeCreditsRemaining, setTotalFreeCreditsRemaining] = React.useState(false)
  const [totalIsItPornRuns, setTotalIsItPornRuns] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [submitting, setSubmitting] = React.useState('')
  const [imgSrc, setImgSrc] = React.useState('')
  const [selectedImage, setSelectedImage] = React.useState(null)
  const [modelRun, setModelRun] = React.useState(null)
  const [orientation, setOrientation] = React.useState(null)

  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  const canRunAlgorithm = (remainingCredits > 0) || (totalFreeCreditsRemaining > 0)

  const creditMessage = generateCreditMessage(totalFreeCreditsRemaining, remainingCredits, currentUserProfile)

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => {

        setImgSrc([reader.result])
        loadImage && loadImage(reader.result, {meta: true}).then(data => {
          const orientation = data && data.exif && data.exif.get('Orientation')
          setOrientation(orientation)
        })
      }
    }
  }, [selectedImage])

  const refreshCredits = () => {
    setCreditsLoading(true)
    return getMyCredits().then(({
      remainingCredits,
      totalFreeCreditsRemaining,
      totalIsItPornRuns
    }) => {
      setCreditsLoading(false)
      setRemainingCredits(remainingCredits)
      setTotalFreeCreditsRemaining(totalFreeCreditsRemaining)
      setTotalIsItPornRuns(totalIsItPornRuns)
    }).catch(err => {
      console.error('Error fetching credits', err)
      setCreditsLoading(false)
      setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingmodelRun', text: 'Error fetching credits.'}))
    })
  }

  React.useEffect(() => {
    refreshCredits()
  }, [dispatch])

  const handleSubmit = () => {
    setSubmitting(true)

    const formData = new FormData()
    if (selectedImage) formData.append("image", selectedImage, selectedImage.name)
    formData.append("algorithmSku", ALGORITHM_SKU_ISITPORN)
    formData.append("orientation", orientation)

    createModelRunFormData(formData).then(modelRun => {
      setSubmitting(false)
      dispatch(addSnackbar({id: 'modelruncreated', text: 'Image analysed.'}))
      if (currentUserProfile) {
        navigate(`/app/account/model-runs/${modelRun.uuid}`)
      } else {
        setModelRun(modelRun)
        refreshCredits()
      }
    }).catch(err => {
      console.error(err)
      setSubmitting(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  const renderFormContent = () => {
    if (submitting || creditsLoading) return (
      <PaperContainer background={mdTheme.palette.primary.main}>
        <CircularProgress sx={{my: 2, color: "primary.contrastText"}} />
      </PaperContainer>
    )

    // output image
    if (modelRun) {
      return (
        <PaperContainer background={mdTheme.palette.primary.main}>
          <IsItPornButton text="Do another" onClick={() => navigate('/algorithms')} />

          <Box sx={{my: 3}} maxWidth="600px">
            <img
              src={modelRun.imageURL}
              height="auto"
              alt="Preview"
              width="auto"
              style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
            />
          </Box>

          <Typography textAlign="center" fontWeight={600} color="primary.contrastText">{creditMessage}</Typography>

          <IsItPornButton text="Purchase Credits" onClick={() => navigate('/app/products')} />
        </PaperContainer>
      )
    }

    // upload form
    return (
      <PaperContainer background={mdTheme.palette.primary.main}>
        {canRunAlgorithm &&
        <label htmlFor="contained-button-file" style={{display: 'flex'}}>
          <input
            style={{display: 'none'}}
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={e =>  {setSelectedImage(e.target.files[0])}}
          />

          <IsItPornButton text="Select Image" />
        </label>}

        {creditMessage &&
        <Typography textAlign="center" fontWeight={600} color="primary.contrastText">
          {creditMessage}
        </Typography>}

        {!canRunAlgorithm &&
        <Box sx={{mt: 2}}>
          <IsItPornButton text="Purchase Credits" onClick={() => navigate('/app/products')} />
        </Box>}

        {imgSrc &&
        <Box sx={{my: 3}} maxWidth="600px">
          <img
            src={imgSrc}
            height="auto"
            alt="Preview"
            width="auto"
            style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
          />
        </Box>}

        {imgSrc &&
        <IsItPornButton text="Submit" onClick={handleSubmit} />}
      </PaperContainer>
    )
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar>
        <Box display="flex" flexGrow="1" flexDirection="row" justifyContent="space-between" alignItems="center">
          <IsItPornButton fontSize="2rem" text="Is It Porn?"/>

          <Box sx={{mr: 2, display: {xs: 'none', sm: 'block'}}}>
            <Button onClick={() => navigate('/app/algorithms')} variant="outlined" color="inherit">More Algorithms</Button>
          </Box>
        </Box>
      </AppBar>

      <Toolbar />

      <Box className="isitporn-bg">
        {errorMessage &&
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>}

        <FullPageContentContainer maxWidth="lg">
          <Box flexDirection="row" justifyContent="center" sx={{display: {sm: 'none', xs: 'flex'}}}>
            <Button onClick={() => navigate('/app/algorithms')} variant="outlined" style={{color: 'white', borderColor: 'white'}}>More Algorithms</Button>
          </Box>

          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {creditsLoading ?
            <CircularProgress sx={{my: 2, color: "primary.contrastText"}} />:
            <Box sx={{my: 2}}>
              <ImpactText
                style={{textAlign: 'center', fontSize: '3rem', lineHeight: 1}}
                text={`${totalIsItPornRuns.toLocaleString()} IMAGES ANALYSED!`}
              />
            </Box>}
          </Box>

          {renderFormContent()}

          <HeroForAlgorithm algorithmSku={ALGORITHM_SKU_ISITPORN} />

          <DescriptionBoxForAlgorithm
            algorithmSku={ALGORITHM_SKU_ISITPORN}
            background={mdTheme.palette.primary.main}
          />
        </FullPageContentContainer>

        <Box sx={{backgroundColor: mdTheme.palette.primary.main, mt: 3}}>
          <Footer color="primary.contrastText" />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
