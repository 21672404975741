export default function IsItPornButton(props) {
  return (
    <div
      onClick={props.onClick}
      style={{fontSize: props.fontSize, paddingTop: '4px', textAlign: 'center'}}
      className='isitporn-button'
    >
      {props.text}
    </div>
  )
}
