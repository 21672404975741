import * as React from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import fetchMe from '../../api/users/fetchMe'
import { setCurrentUserProfile } from '../../redux/currentUserProfile'
import { addSnackbar } from '../../redux/snackbars'

import AppBar from '../../components/AppBar'
import Copyright from '../../components/Copyright'

import mdTheme from '../../themes/iipTheme'

export default function CompleteSSO() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const fetchUserProfile = () => {
    // if user has session cookie but no profile in state,
    // we get the profile into state here.
    // if user already had profile in state, we're refreshing it. in this case,
    // show a warning on failure.
    fetchMe().then(user => {
      dispatch(setCurrentUserProfile(user))
      navigate('/app')
    }).catch(err => {
      console.error(err)
      if (err.response && err.response.data && err.response.data.error === 'You must be signed in.') {
        dispatch(addSnackbar({id: 'sessionexpired', text: 'Sign-in failed.'}))
      }
      navigate('/app')
      dispatch(setCurrentUserProfile(null))
    })
  }

  // run once
  React.useEffect(() => {
    fetchUserProfile()
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Completing signin..."/>

      <Toolbar />

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Completing signin...
          </Typography>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
