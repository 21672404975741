import * as React from 'react'
import { useNavigate, useLocation } from "react-router-dom"

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import InventoryIcon from '@mui/icons-material/Inventory'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ImageIcon from '@mui/icons-material/Image'
import SmartToyIcon from '@mui/icons-material/SmartToy'

const NavListItem = (props) => {
  const {
    path
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem selected={location.pathname.startsWith(path)} button onClick={() => navigate(path)}>
      {props.children}
    </ListItem>
  )
}

export const MainListItems = () => {
  return (
    <Box>
      <NavListItem path='/app/admin/users'>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </NavListItem>
      <NavListItem path='/app/admin/products'>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </NavListItem>
      <NavListItem path='/app/admin/transactions'>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </NavListItem>
      <NavListItem path='/app/admin/model-runs'>
        <ListItemIcon>
          <ImageIcon />
        </ListItemIcon>
        <ListItemText primary="Model Runs" />
      </NavListItem>
      <NavListItem path='/app/admin/algorithms'>
        <ListItemIcon>
          <SmartToyIcon />
        </ListItemIcon>
        <ListItemText primary="Algorithms" />
      </NavListItem>
    </Box>
  )
}
