import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import emailValid from '../../../../helpers/validation/emailValid'
import sendContactEmail from '../../../../api/contact/send'

import { addSnackbar } from '../../../../redux/snackbars'


export default function ContactForm({
  setErrorMessage
}) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [content, setContent] = React.useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    setSubmitting(true)

    try {
      const recaptchaToken = await executeRecaptcha('contactform').then()

      await sendContactEmail({email, name, content, recaptchaToken})
      setSubmitting(false)

      dispatch(addSnackbar({id: 'emailsent', text: 'Email sent.'}))

      navigate('/app/home')
    } catch (err) {
      console.error(err)
      setSubmitting(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    }
  }

  const submitDisabled = !(email && name && content)
  const emailValidationError = email && !emailValid(email) && 'Email invalid.'

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            onChange={e => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="content"
            label="Message"
            name="content"
            multiline
            rows={10}
            onChange={e => setContent(e.target.value)}
          />
        </Grid>
      </Grid>

      {emailValidationError &&
      <Typography color="error" sx={{ mt: 3 }}>
        {emailValidationError}
      </Typography>}

      <LoadingButton
        disabled={submitDisabled}
        type="submit"
        loading={submitting}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {submitting ? 'Sending...' : 'Send' }
      </LoadingButton>

    </Box>
  )
}
