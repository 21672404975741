import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import { ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import AppBar from '../../components/AppBar'
import FullPageContentContainer from '../../components/FullPageContentContainer'
import Footer from '../../components/Footer'

import getProduct from '../../api/products/get'

import mdTheme from '../../themes/iipTheme'
import HeroForAlgorithm from '../../components/HeroForAlgorithm'
import centsToDollarString from '../../helpers/money/centsToDollarString'

import {
  ALGORITHM_SKU_ISITPORN,
  ALGORITHM_SKU_FSTKANAGAWA,
  ALGORITHM_SKU_THESCREAM,
  ALGORITHM_SKU_STORYMACHINE
} from '../../consts'

export default function ShowProduct() {
  const { productUuid } = useParams()
  const navigate = useNavigate()

  const [productLoading, setProductLoading] = React.useState(true)
  const [product, setProduct] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const handleReceiveProduct = (product) => {
    setProduct(product)
  }

  React.useEffect(() => {
    getProduct(productUuid).then(product => {
      if (!product.purchasable) return navigate('/app/home')
      handleReceiveProduct(product)
      setProductLoading(false)
    }).catch(err => {
      console.error(err)
      setProductLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }, [productUuid])

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title={(productLoading || !product) ? "Show Product" : product.name}/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      {/* <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/products')}} underline="hover" color="inherit" href="/app/products">
            Products
          </Link>

          <Typography color="text.primary">
            {product && (product.name || <i>Unknown</i>)}
            {productLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {!productLoading && !product && 'Unknown'}
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider /> */}

      {productLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center', mt: 3 }}>
        <CircularProgress />
      </Box>}

      {!productLoading && product &&
      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{mb: 6, mt: 6}}
        >
          {product.name}
        </Typography>

        {product && product.amountCents &&
        <Box display="flex" justifyContent="center" sx={{mb: 6}}>
          <Button variant="outlined" component="span" sx={{mb: 2}} onClick={() => navigate(`/app/products/${product.uuid}/transactions/new`)}>
            <ShoppingCartIcon sx={{mr: 1}} /> Purchase for {centsToDollarString(product.amountCents)}
          </Button>
        </Box>}

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          <span style={{whiteSpace: 'pre-wrap'}}>
            {product.description}
          </span>
        </Typography>

        <Box sx={{mb: 6}}>
          <HeroForAlgorithm algorithmSku={ALGORITHM_SKU_ISITPORN} />
          <HeroForAlgorithm algorithmSku={ALGORITHM_SKU_STORYMACHINE} />
          <HeroForAlgorithm algorithmSku={ALGORITHM_SKU_FSTKANAGAWA} />
          <HeroForAlgorithm algorithmSku={ALGORITHM_SKU_THESCREAM} />
        </Box>

        {product.coverImageURL &&
        <Box sx={{mb: 6}} maxWidth="600px">
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img
              src={product.coverImageURL}
              height="auto"
              width="auto"
              alt={`Product ${product.name}`}
              style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
            />
          </div>
        </Box>}
      </FullPageContentContainer>}

      <Footer />
    </ThemeProvider>
  );
}
