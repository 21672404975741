import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { removeSnackbar } from '../redux/snackbars'

export default function Snackbars(props) {
  const snackbars = useSelector(state => state.snackbars)
  const dispatch = useDispatch()

  return (
    <div>
      {snackbars.map(snackbar => {
        const handleClose = () => dispatch(removeSnackbar({id: snackbar.id}))

        const action = (
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        )

        return (
          <Snackbar
            open
            action={action}
            key={snackbar.id}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbar.text}
          />
        )
      })}
    </div>
  );
}
