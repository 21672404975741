import * as React from 'react'
import Helmet from 'react-helmet'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import mdTheme from '../../themes/iipTheme'

export default function Privacy() {
  // const navigate = useNavigate()

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Privacy Statement"/>

      <Helmet title="Privacy Statement" />

      <Toolbar />

      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="left"
          color="text.primary"
          gutterBottom
        >
          Privacy Statement
        </Typography>

        <Typography variant="p">
          Data you enter into forms on this website is stored in a database hosted in the United States.
          <br/>
          <br/>
          To request to have your account data deleted please reach out via contact form.
          <br/>
          <br/>
          Recaptcha and analytics services are provided by Google and Google will likely track your usage.
          <br/>
          <br/>
          Payment services are provided by Stripe and Stripe will store your payment information and likely track your usage.
          <br/>
          <br/>
          Cookies are used on this site for user authentication and session tracking.
          <br/>
          <br/>
          Text entered into this site for certain algorithms will be sent to OpenAI along with your user ID.
          <br/>
          <br/>
          Where Facebook login is used, Facebook will be collecting data about your usage of this application.
        </Typography>

      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}