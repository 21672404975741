import * as React from 'react'
import { useNavigate, useLocation } from "react-router-dom"

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PasswordIcon from '@mui/icons-material/Password'
import ImageIcon from '@mui/icons-material/Image'
import CreditCardIcon from '@mui/icons-material/CreditCard'

const NavListItem = (props) => {
  const {
    path
  } = props

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem selected={location.pathname.startsWith(path)} button onClick={() => navigate(path)}>
      {props.children}
    </ListItem>
  )
}

export const MainListItems = () => {
  return (
    <Box>
      <NavListItem path='/app/account/model-runs'>
        <ListItemIcon>
          <ImageIcon />
        </ListItemIcon>
        <ListItemText primary="Model Runs" />
      </NavListItem>
      <NavListItem path='/app/account/profile'>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </NavListItem>
      <NavListItem path='/app/account/payment-methods'>
        <ListItemIcon>
          <CreditCardIcon />
        </ListItemIcon>
        <ListItemText primary="Payment Methods" />
      </NavListItem>
      <NavListItem path='/app/account/transactions'>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </NavListItem>
      <NavListItem path='/app/account/change-password'>
        <ListItemIcon>
          <PasswordIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </NavListItem>
    </Box>
  )
}
