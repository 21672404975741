export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api'

export const ALGORITHM_TYPE_ISITPORN = 'isitporn'
export const ALGORITHM_TYPE_FASTSTYLETRANSFER = 'faststyletransfer'
export const ALGORITHM_TYPE_STORYMACHINE = 'storymachine'

export const ALGORITHM_SKU_ISITPORN = 'isitporn'

export const ALGORITHM_SKU_FSTKANAGAWA = 'fstkanagawa'
export const ALGORITHM_SKU_THESCREAM = 'thescream'
export const ALGORITHM_SKU_STARRYNIGHT = 'starrynight'
export const ALGORITHM_SKU_GUERNICA = 'guernica'
export const ALGORITHM_SKU_MOSAIC = 'mosaic'
export const ALGORITHM_SKU_CANDY = 'candy'

export const ALGORITHM_SKU_STORYMACHINE = 'storymachine'
export const ALGORITHM_SKU_POEMMACHINE = 'poemmachine'
export const ALGORITHM_SKU_GREENTEXT = 'greentext'

export const PRODUCT_SKU_ISITPORNCREDITS100 = 'isitporncredits100'
export const PRODUCT_SKU_ISITPORNCREDITS50 = 'isitporncredits50'
