import axios from '../axios'
import { API_BASE_URL } from '../../consts'

import { getBrowserFingerprint } from '../../lib/fingerprintjs'

export default function getMyCredits() {
  return getBrowserFingerprint().then(browserFingerprint => {
    return axios.get(`${API_BASE_URL}/credits/mine?bf=${browserFingerprint}`).then(response => (response.data))
  })
}
