import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import PaperContainer from '../components/PaperContainer'

import {
  ALGORITHM_SKU_FSTKANAGAWA,
  ALGORITHM_SKU_ISITPORN,
} from '../consts'

export default function DescriptionBoxForAlgorithm({
  algorithmSku,
  description,
  background
}) {
  if (algorithmSku === ALGORITHM_SKU_ISITPORN) {
    return (
      <PaperContainer background={background}>
        <Typography fontWeight={600} sx={{textAlign: 'center'}} color="primary.contrastText">
          Is It Porn? uses a proprietary neural network algorithm to
          detect pornography in images and generate memes.
          <br/>Sign up to retain your history of uploads.
        </Typography>
      </PaperContainer>
    )
  }

  if (algorithmSku === ALGORITHM_SKU_FSTKANAGAWA) {
    return (
      <PaperContainer background={background}>
        <Typography fontWeight={600} sx={{textAlign: 'center'}} color="primary.contrastText">
          This generator uses the algorithm from the paper
          {' '}"<Link color="primary.contrastText" href="https://arxiv.org/abs/1705.06830">Exploring the structure of a real-time, arbitrary neural artistic stylization network</Link>"{' '}
          to transfer the style of one image onto another.
        </Typography>
      </PaperContainer>
    )
  }

  if (description) {
    return (
      <PaperContainer background={background}>
        <Typography fontWeight={600} sx={{textAlign: 'center'}} color="primary.contrastText">
          {description}
        </Typography>
      </PaperContainer>
    )
  }

  return null
}
