import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import IsItPornButton from '../../../../components/IsItPornButton'
import getMyCredits from '../../../../api/credits/mine'
import { createModelRunFormData } from '../../../../api/modelRuns/create'
import {addSnackbar} from '../../../../redux/snackbars'
import PaperContainer from '../../../../components/PaperContainer'
import generateCreditMessage from '../../../../helpers/credits/generateCreditMessage'

const {
  loadImage
} = window

export default function CreateModelRunForm({
  background,
  algorithmSku,
  setErrorMessage,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [creditsLoading, setCreditsLoading] = React.useState(true)
  const [remainingCredits, setRemainingCredits] = React.useState(0)
  const [totalFreeCreditsRemaining, setTotalFreeCreditsRemaining] = React.useState(false)

  const [submitting, setSubmitting] = React.useState('')
  const [imgSrc, setImgSrc] = React.useState('')
  const [selectedImage, setSelectedImage] = React.useState(null)
  const [modelRun, setModelRun] = React.useState(null)
  const [orientation, setOrientation] = React.useState(null)

  const currentUserProfile = useSelector((state) => state.currentUserProfile)
  const canRunAlgorithm = (remainingCredits > 0) || totalFreeCreditsRemaining
  const creditMessage = generateCreditMessage(totalFreeCreditsRemaining, remainingCredits, currentUserProfile)

  const refreshCredits = () => {
    setCreditsLoading(true)
    return getMyCredits().then(({
      remainingCredits,
      totalFreeCreditsRemaining,
    }) => {
      setCreditsLoading(false)
      setRemainingCredits(remainingCredits)
      setTotalFreeCreditsRemaining(totalFreeCreditsRemaining)
    }).catch(err => {
      console.error('Error fetching credits', err)
      setCreditsLoading(false)
      setErrorMessage && setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingmodelRun', text: 'Error fetching credits.'}))
    })
  }

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => {
        setImgSrc([reader.result])

        loadImage && loadImage(reader.result, {meta: true}).then(data => {
          const orientation = data && data.exif && data.exif.get('Orientation')
          setOrientation(orientation)
        })
      }
    }
  }, [selectedImage])

  React.useEffect(() => {
    refreshCredits()
  }, [dispatch])

  const handleSubmit = () => {
    setSubmitting(true)

    const formData = new FormData()
    if (selectedImage) formData.append("image", selectedImage, selectedImage.name)
    formData.append("algorithmSku", algorithmSku)
    formData.append("orientation", orientation)

    createModelRunFormData(formData).then(modelRun => {
      setSubmitting(false)
      dispatch(addSnackbar({id: 'modelruncreated', text: 'Image analysed.'}))
      if (currentUserProfile) {
        navigate(`/app/account/model-runs/${modelRun.uuid}`)
      } else {
        setModelRun(modelRun)
      }
    }).catch(err => {
      console.error(err)
      setSubmitting(false)
      setErrorMessage && setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  if (!algorithmSku) return <Box>No algorithm provided.</Box>

  const renderFormContent = () => {
    if (submitting || creditsLoading) {
      return (
        <PaperContainer background={background}>
          <CircularProgress sx={{my: 2, color: "primary.contrastText"}} />
        </PaperContainer>
      )
    }

    if (modelRun) {
      return (
        <PaperContainer background={background}>
          <IsItPornButton text="Do another" onClick={() => setModelRun(null)} />

          <Box sx={{my: 3}} maxWidth="600px">
            <img
              src={modelRun.imageURL}
              height="auto"
              alt="Preview"
              width="auto"
              style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
            />
          </Box>

          {creditMessage &&
          <Typography textAlign="center" fontWeight={600} color="primary.contrastText">{creditMessage}</Typography>}

          <IsItPornButton text="Purchase Credits" onClick={() => navigate('/app/products')} />
        </PaperContainer>
      )
    }

    return (
      <PaperContainer background={background}>
        {Boolean(canRunAlgorithm) &&
        <label htmlFor="contained-button-file" style={{display: 'flex'}}>
          <input
            style={{display: 'none'}}
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={e =>  {setSelectedImage(e.target.files[0])}}
          />

          <IsItPornButton text="Select Image" />
        </label>}

        {creditMessage &&
        <Typography textAlign="center" fontWeight={600} color="primary.contrastText">
          {creditMessage}
        </Typography>}

        {!canRunAlgorithm &&
        <Box sx={{mt: 2}}>
          <IsItPornButton text="Purchase Credits" onClick={() => navigate('/app/products')} />
        </Box>}

        {imgSrc &&
        <Box sx={{my: 3}} maxWidth="600px">
          <img
            src={imgSrc}
            height="auto"
            alt="Preview"
            width="auto"
            style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
          />
        </Box>}

        {imgSrc &&
        <IsItPornButton text="Submit" onClick={handleSubmit} />}
      </PaperContainer>
    )
  }

  return (
    <Box maxWidth="lg">
      {renderFormContent()}
    </Box>
  )
}