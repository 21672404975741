import React from 'react'
import { useLocation } from 'react-router-dom'

import googleAanalytics from '../lib/googleAnalytics'

export default function FetchUserProfile(props) {
  const location = useLocation()

  const [lastLoggedPage, setLastLoggedPage] = React.useState(null)

  React.useEffect(() => {
    if (lastLoggedPage !== window.location.pathname) {
      googleAanalytics.pageview(window.location.pathname)
      setLastLoggedPage(window.location.pathname)
    }
  }, [lastLoggedPage, location])

  return props.children
}
