import React from 'react'
import ReactDOM from 'react-dom'
import Helmet from 'react-helmet'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@mui/material/CssBaseline'

import Snackbars from './components/Snackbars'
import Router from './Router';

import store, { persistor } from './redux'

import reportWebVitals from './reportWebVitals'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />

    <Helmet defaultTitle="Is It Porn?" />

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Snackbars />
        <Router />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
