import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'

import deleteUserPaymentMethod from '../../api/userPaymentMethods/deleteUserPaymentMethod'

import { addSnackbar } from '../../redux/snackbars'

export default function PaymentMethod({
  paymentMethod,
  onDelete,

  selected,
  onClick
}) {
  const dispatch = useDispatch()
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [deleting, setDeleting] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const handleDelete = () => {
    setDeleting(true)
    deleteUserPaymentMethod(currentUserProfile.uuid, paymentMethod.id).then(() => {
      onDelete()
      dispatch(addSnackbar({id: `cardremovalsucess${paymentMethod.id}`, text: 'Card removal success.'}))
    }).catch(error => {
      console.error(error)
      setErrorMessage((error.response && error.response.data && error.response.data.error) || error.message)
      setDeleting(false)
    })
  }

  return (
    <Paper
      id={paymentMethod.id}
      elevation={selected ? 24 : 2}
      sx={{ p: 3}}
      onClick={() => onClick && onClick(paymentMethod)}
      style={{
        cursor: onClick ? 'pointer' : 'default'
      }}
    >
      {errorMessage &&
      <Stack sx={{ width: '100%', mb: 3 }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      {!paymentMethod.card && <div>Unsupported payment method</div>}

      {paymentMethod.card &&
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>{(paymentMethod.card.brand && paymentMethod.card.brand.toUpperCase()) || 'Unknown Brand'}</div>
          <div>{paymentMethod.card.last4 ? `**** **** **** ${paymentMethod.card.last4}` : 'Unknown Last4'}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div>{paymentMethod.card.exp_month || 'Unknown Month'}/{paymentMethod.card.exp_year || 'Unknown Year'}</div>
        </div>

        <div>Added {new moment(paymentMethod.created * 1000).fromNow()}</div>
      </div>}

      <Grid
        sx={{ mt: 0 }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >

        {!selected && <div />}
        {selected &&
        <Button
          color="info"
          variant="contained"
          sx={{mt: 1}}
          disabled
        >
          SELECTED
        </Button>}

        {onDelete &&
        <LoadingButton
          onClick={handleDelete}
          disabled={deleting}
          loading={deleting}
          sx={{mt: 1}}
          color="info"
          variant="contained"
        >
          Delete
        </LoadingButton>}
      </Grid>
    </Paper>
  )
}
