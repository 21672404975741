import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

export function getBrowserFingerprint () {
  return fpPromise
    .then(fp => fp.get())
    .then(result => {
      return result.visitorId
    })
}
