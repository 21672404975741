import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import getAlgorithm from '../../../../api/algorithms/get'
import deleteAlgorithm from '../../../../api/algorithms/delete'
import updateAlgorithm from '../../../../api/algorithms/update'

import { addSnackbar } from '../../../../redux/snackbars'

export default function AdminEditAlgorithm() {
  const { algorithmUuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [algorithmLoading, setAlgorithmLoading] = React.useState(true)
  const [algorithm, setAlgorithm] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [selectedImage, setSelectedImage] = React.useState(null)

  const [imgSrc, setImgSrc] = React.useState('')

  const [name, setName] = React.useState('')
  const [hidden, setHidden] = React.useState(false)
  const [sku, setSku] = React.useState('')
  const [type, setType] = React.useState('')
  const [description, setDescription] = React.useState('')

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [deleteModalShown, setDeleteModalShown] = React.useState(false)

  const handleReceiveAlgorithm = (algorithm) => {
    setAlgorithm(algorithm)
    setName(algorithm.name)
    setHidden(algorithm.hidden)
    setSku(algorithm.sku)
    setType(algorithm.type)
    setDescription(algorithm.description)
    setImgSrc(undefined)
  }

  const handleSave = () => {
    setSaving(true)

    const formData = new FormData()
    if (selectedImage) formData.append("coverImage", selectedImage, selectedImage.name)
    if (name) formData.append('name', name)
    formData.append('hidden', hidden)
    if (sku) formData.append('sku', sku)
    if (type) formData.append('type', type)
    if (description) formData.append('description', description)

    updateAlgorithm(algorithmUuid, formData).then(algorithm => {
      handleReceiveAlgorithm(algorithm)
      setSaving(false)
      dispatch(addSnackbar({id: 'adminalgorithmupdated', text: 'Algorithm updated.'}))
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  const handleDelete = () => {
    setSaving(true)
    deleteAlgorithm(algorithmUuid).then(() => {
      dispatch(addSnackbar({id: `algorithmdeletesuccess${algorithmUuid}`, text: 'Algorithm deleted.'}))
      navigate('/app/admin/algorithms')
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => setImgSrc([reader.result])
    }
  }, [selectedImage])

  React.useEffect(() => {
    getAlgorithm(algorithmUuid).then(algorithm => {
      setAlgorithmLoading(false)
      handleReceiveAlgorithm(algorithm)
    }).catch(err => {
      console.error(err)
      setAlgorithmLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }, [algorithmUuid])

  return (
    <div>
      <Dialog onClose={() => setDeleteModalShown(false)} open={deleteModalShown}>
        <DialogTitle>Are you sure?</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete algorithm {algorithm && algorithm.name}? This is permanent.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDeleteModalShown(false)}>Cancel</Button>
          <Button color="error" variant="contained" onClick={() => handleDelete()}>Delete</Button>
        </DialogActions>
      </Dialog>

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/admin/algorithms')}} underline="hover" color="inherit" href="/app/admin/algorithms">
            Algorithms
          </Link>

          <Typography color="text.primary">
            {algorithm && (algorithm.name || <i>Unknown</i>)}
            {algorithmLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {!algorithmLoading && !algorithm && 'Unknown'}
          </Typography>
        </Breadcrumbs>

        <div>
          <Button onClick={() => setDeleteModalShown(true)} disabled={isSaving || algorithmLoading} sx={{mr: 2}} color="error" variant="outlined">Delete</Button>
          <Button onClick={() => navigate(`/app/algorithms/${algorithm.uuid}`)} sx={{mr: 2}} variant="outlined">Show</Button>
        </div>
      </HeadingContainer>

      <Divider />

      {algorithmLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!algorithmLoading &&
      <Container maxWidth="sm" sx={{pt: 3}}>
        <FormGroup>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  value={name || ''}
                  label="Name"
                  onChange={e => {setDirty(true); setName(e.target.value)}}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextField
                  name="sku"
                  required
                  fullWidth
                  id="sku"
                  value={sku || ''}
                  label="SKU"
                  onChange={e => {setDirty(true); setSku(e.target.value)}}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextField
                  name="type"
                  required
                  fullWidth
                  id="type"
                  value={type || ''}
                  label="Type"
                  onChange={e => {setDirty(true); setType(e.target.value)}}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  required
                  fullWidth
                  id="description"
                  multiline
                  rows={4}
                  value={description || ''}
                  label="Description"
                  onChange={e => {setDirty(true); setDescription(e.target.value)}}
                />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <FormControlLabel
                label="Hidden?"
                control={<Checkbox checked={hidden} onChange={e => {setDirty(true); setHidden(e.target.checked)}} />}
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <label htmlFor="contained-button-file">
                  <input
                    style={{display: 'none'}}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={e =>  {setDirty(true); setSelectedImage(e.target.files[0])}}
                  />

                  <Button fullWidth variant="outlined" component="span" sx={{mb: 2}}>
                    <PhotoCamera sx={{mr: 1}} /> Upload Cover Image
                  </Button>
                </label>

                <Box maxWidth='600px'>
                  {(imgSrc || (algorithm && algorithm.coverImageURL)) &&
                  <img
                    alt="Cover"
                    src={imgSrc || algorithm.coverImageURL}
                    height="auto"
                    width="auto"
                    style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
                  />}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <LoadingButton fullWidth onClick={handleSave} disabled={!isDirty || algorithmLoading || !algorithm} loading={isSaving} variant="contained">Save</LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  )
}
