import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import { createModelRunFormData } from '../../../../api/modelRuns/create'
import { ALGORITHM_SKU_ISITPORN } from '../../../../consts'

import { addSnackbar } from '../../../../redux/snackbars'

export default function NewModelRun() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = React.useState('')

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [imgSrc, setImgSrc] = React.useState('')
  const [selectedImage, setSelectedImage] = React.useState(null)

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => setImgSrc([reader.result])
    }
  }, [selectedImage])

  const handleSave = () => {
    setSaving(true)

    const formData = new FormData()
    if (selectedImage) formData.append("image", selectedImage, selectedImage.name)
    formData.append("algorithmSku", ALGORITHM_SKU_ISITPORN)

    createModelRunFormData(formData).then(modelRun => {
      setSaving(false)
      dispatch(addSnackbar({id: 'modelruncreated', text: 'Model run created.'}))
      navigate(`/app/account/model-runs/${modelRun.uuid}`)
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/account/model-runs')}} underline="hover" color="inherit" href="/app/account/model-runs">
            Model Runs
          </Link>

          <Typography color="text.primary">
            New Model Run
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="sm" sx={{pt: 3}}>
        <FormGroup>
          <Box component="form" noValidate>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <label htmlFor="contained-button-file">
                  <input
                    style={{display: 'none'}}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={e =>  {setDirty(true); setSelectedImage(e.target.files[0])}}
                  />

                  <Button fullWidth variant="outlined" component="span" sx={{mb: 3}}>
                    <PhotoCamera sx={{mr: 1}} /> Upload Image
                  </Button>
                </label>

                {imgSrc &&
                <Box sx={{mb: 3}} display="flex" flexDirection="column" alignItems="center" maxWidth='600px'>
                  <img
                    alt="Uploaded and to be analysed"
                    src={imgSrc}
                    height="auto"
                    width="auto"
                    style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
                  />
                </Box>}

                <LoadingButton
                  fullWidth
                  imgSrc
                  onClick={handleSave}
                  disabled={!isDirty}
                  loading={isSaving}
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
