import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import { setCurrentUserProfile } from '../../redux/currentUserProfile'
import { addSnackbar } from '../../redux/snackbars'

import AppBar from '../../components/AppBar'
import FullPageContentContainer from '../../components/FullPageContentContainer'
import Footer from '../../components/Footer'

import signin from '../../api/auth/signin'

import mdTheme from '../../themes/iipTheme'

export default function SignIn() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState('')

  const currentUserProfile = useSelector((state) => state.currentUserProfile)
  React.useEffect(() => {
    if (currentUserProfile) return navigate('/app/account')
  })

  React.useEffect(() => {
    setSubmitDisabled(!(email && password))
  }, [email, password])

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    signin(email, password)
      .then(profile => {
        setSubmitting(false)

        dispatch(setCurrentUserProfile(profile))
        dispatch(addSnackbar({id: 'signinsuccess', text: 'Sign in successful.'}))

        navigate("/app")
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        if (err.response && err.response.data && err.response.data.redirect)
          return navigate(err.response.data.redirect)
        setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
      })
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Sign In"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}


      <FullPageContentContainer maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} width="100%">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>

            <Button
              disabled={submitting || submitDisabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {submitting ? 'Submitting...' : 'Sign In' }
            </Button>

            <Grid container justifyContent="center">
              <Grid item style={{paddingRight: '1rem'}}>
                <Link href="/app/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>

            {/* <Box display="flex" flexDirection="column" alignItems="center" sx={{mt: 2}} item>
              <a href="/api/auth/facebook">
                <img
                  style={{maxWidth: '200px'}}
                  src="/img/facebook-continue.png"
                  alt="Continue with Facebook"
                />
              </a>
            </Box> */}
          </Box>
        </Box>
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}
