import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchUsers({email, name, page, perPage}) {
  const params = new URLSearchParams()

  email && params.set('email', email)
  name && params.set('name', name)
  page && params.set('page', page)
  perPage && params.set('perPage', perPage)

  return axios.get(`${API_BASE_URL}/users?${params.toString()}`).then(response => ({
    users: response.data,
    totalPages: Number(response.headers['x-total-pages'])
  }))
}
