import * as React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import Button from '@mui/material/Button'

import centsToDollarString from '../../../../helpers/money/centsToDollarString'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'
import FilterContainer from '../../../../components/FilterContainer'

import searchTransactions from '../../../../api/transactions/search'

let queryTimeout

const PER_PAGE = 10

export default function AdminTransactionManagement() {
  const navigate = useNavigate()
  const [transactionsLoading, setTransactionsLoading] = React.useState(true)
  const [transactions, setTransactions] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  const [email, setEmail] = React.useState('')
  const [showAbandoned, setShowAbandoned] = React.useState(false)

  const handleSearchTransactions = () => {
    searchTransactions({
      email,
      showAbandoned,
      page,
      perPage: PER_PAGE
    }).then(({
      transactions,
      totalPages
    }) => {
      setTotalPages(totalPages)
      setTransactions(transactions)
      setTransactionsLoading(false)
    }).catch(err => {
      console.error(err)
      setTransactionsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    setTransactionsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchTransactions()
    }, 300)
  }, [email, showAbandoned, page])

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Transactions
        </Typography>

        <Button onClick={() => navigate('/app/admin/transactions/new')} variant="contained">Add Transaction</Button>
      </HeadingContainer>

      <Divider />

      <FilterContainer>
        {/* <FilterList sx={{ fontSize: 22 }} /> */}
        {/* <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b> */}

        {/* <TextField
          style={{ paddingRight: '1rem' }}
          size="small"
          value={email || ''}
          onChange={e => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        /> */}

        <Checkbox checked={showAbandoned} onChange={e => setShowAbandoned(e.target.checked)} />
        <span style={{fontSize: '13px', paddingRight: '1rem'}}>Show abandoned?</span>
      </FilterContainer>

      <Divider sx={{mb: 2}} />

      {transactionsLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!transactionsLoading &&
      <Container maxWidth={false}>
        <Pagination
          sx={{mb: 3}}
          count={totalPages}
          page={page}
          onChange={(_, v) => setPage(v)}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Created At</b></TableCell>
                <TableCell align="left"><b>Email</b></TableCell>
                <TableCell align="left"><b>Amount</b></TableCell>
                <TableCell align="left"><b>Product</b></TableCell>
                <TableCell align="left"><b>Stripe Customer ID</b></TableCell>
                <TableCell align="left"><b>Stripe Charge ID</b></TableCell>
                {/* <TableCell align="right" /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions && transactions.map((transaction) => (
                <TableRow
                  key={transaction.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{new moment(transaction.createdAt).format('llll')}</TableCell>
                  <TableCell align="left">{transaction.user && transaction.user.email}</TableCell>
                  <TableCell align="left">{centsToDollarString(transaction.amountCents)}</TableCell>
                  <TableCell align="left">{transaction.product && transaction.product.name}</TableCell>
                  <TableCell align="left">{transaction.stripeCustomerId || (transaction.createdByAdmin && 'Created by admin')}</TableCell>
                  <TableCell align="left">{transaction.stripeChargeId || (transaction.createdByAdmin && 'Created by admin')}</TableCell>
                  {/* <TableCell align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => navigate(`/app/admin/transactions/${transaction.uuid}`)}
                    >
                      Edit
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
