import { createSlice } from '@reduxjs/toolkit'

export const snackbars = createSlice({
  name: 'snackbars',
  initialState: [],
  reducers: {
    addSnackbar: (state, action) => {
      return [action.payload, ...state]
    },
    removeSnackbar: (state, action) => {
      return state.filter(s => (s.id !== action.payload.id))
    },
  },
})

export const { addSnackbar, removeSnackbar } = snackbars.actions

export default snackbars.reducer
