import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import EmailIcon from '@mui/icons-material/Email'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import AppBar from '../../components/AppBar'

import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import mdTheme from '../../themes/iipTheme'

import ContactForm from './components/ContactForm'


export default function Contact() {
  const [errorMessage, setErrorMessage] = React.useState('')

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Sign Up"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <FullPageContentContainer maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <EmailIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Contact
          </Typography>

          <Typography component="p" variant="p" sx={{py: 3}}>
            Report bugs or request help here.
          </Typography>

          <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
            <ContactForm setErrorMessage={setErrorMessage} />
          </GoogleReCaptchaProvider>
        </Box>
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}
