import React from 'react'
import { Elements } from '@stripe/react-stripe-js'

import stripePromise from '../lib/stripe'

export default function StripePaymentIntentForTransactionProvider({
  transaction,

  children
}) {
  const clientSecret = transaction && transaction.paymentIntent && transaction.paymentIntent.client_secret

  if (!clientSecret) return <div>No client secret for transaction.</div>

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: { theme: 'flat', },
        clientSecret,
      }}
    >
      {children}
    </Elements>
  )
}
