import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'

import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import HeadingContainer from '../../components/HeadingContainer'

import Copyright from '../../components/Copyright'
import mdTheme from '../../themes/iipTheme'
import fetchTransaction from '../../api/transactions/get'

// import TransactionStatus from './components/TransactionStatus'
// import StripePaymentIntentForTransactionProvider from '../../hoc/StripePaymentIntentForTransactionProvider'

import { addSnackbar } from '../../redux/snackbars'

export default function TransactionComplete() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { transactionUuid } = useParams()

  const [errorMessage, setErrorMessage] = React.useState('')
  const [transaction, setTransaction] = React.useState(null)
  const [transactionLoading, setTransactionLoading] = React.useState(true)

  React.useEffect(() => {
    setTransactionLoading(true)
    fetchTransaction(transactionUuid).then(transaction => {
      setTransactionLoading(false)
      setTransaction(transaction)
    }).catch(err => {
      console.error(err)
      setTransactionLoading(false)
      setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingtransaction', text: 'Error fetching transaction.'}))
    })
  }, [transactionUuid])


  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title='Transaction '/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/account/transactions')}} underline="hover" color="inherit" href="/app/account/transactions">
            Transactions
          </Link>

          <Typography color="text.primary">
            {transaction &&
            <Link onClick={e => {e.preventDefault(); navigate(`/app/account/transactions/${transaction.uuid}`)}} underline="hover" color="inherit" href={`/app/account/transactions/${transaction.uuid}`}>
              {transaction.uuid}
            </Link>}
            {!transactionLoading && !transaction && 'Unknown'}
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
          </Typography>

          <Typography color="text.primary">
            Complete
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="sm">
        {!transaction &&
        <Box sx={{ display: 'flex', justifyContent:  'center' }}>
          <CircularProgress />
        </Box>}

        {transaction &&
        <Typography color="text.primary" component="h1" variant="h1" sx={{mt: 10, mb: 10}}>Transaction Complete!</Typography>}
      </Container>


      <Copyright sx={{ pt: 4 }} />
    </ThemeProvider>
  );
}
