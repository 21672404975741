import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

export default function IsItPornHero() {
  return (
    <Grid container>
      <Grid
        item
        sm={4}
        xs={12}
        sx={{p: 1}}
      >
        <Card sx={{display: 'flex', overflow: 'hidden'}}>
          <img
            style={{width: '100%'}}
            alt="Exmaple caution"
            src="/img/iip-example-safe.png"
          />
        </Card>
      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        sx={{p: 1}}
      >
        <Card sx={{display: 'flex', overflow: 'hidden'}}>
          <img
            style={{width: '100%'}}
            alt="Exmaple caution"
            src="/img/iip-example-caution.png"
          />
        </Card>
      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        sx={{p: 1}}
      >
        <Card sx={{display: 'flex', overflow: 'hidden'}}>
          <img
            style={{width: '100%'}}
            alt="Exmaple caution"
            src="/img/iip-example-inappropriate.png"
          />
        </Card>
      </Grid>
    </Grid>
  )
}