import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Elements,
} from '@stripe/react-stripe-js'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

import stripePromise from '../lib/stripe'
import { addSnackbar } from '../redux/snackbars'
import createSetupIntent from '../api/userPaymentMethods/createSetupIntent'

export default function StripeSetupIntentProvider(props) {
  const dispatch = useDispatch()
  const [setupIntent, setSetupIntent] = React.useState(null)

  const currentUserProfile = useSelector(state => state.currentUserProfile)

  React.useEffect(() => {
    createSetupIntent(currentUserProfile.uuid).then(setupIntent => {
      setSetupIntent(setupIntent)
    }).catch(err => {
      console.error(err)
      dispatch(addSnackbar({id: 'errorcreatingtransaction', text: 'Error creating transaction.'}))
    })
  }, [currentUserProfile])

  if (!setupIntent) return (
    <Box sx={{ display: 'flex', justifyContent:  'center', mb: 3 }}>
      <CircularProgress />
    </Box>
  )

  return (
    <Elements
      stripe={stripePromise}
    >
      {React.cloneElement(props.children, { setupIntent })}
    </Elements>
  )
}
