import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import createResetPasswordRequest from '../../api/resetPasswordRequests/create'

import mdTheme from '../../themes/iipTheme'

export default function ForgotPassword() {
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [submitting, setSubmitting] = React.useState(false)

  const [email, setEmail] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState('error')

  React.useEffect(() => {
    setSubmitDisabled(!email)
  }, [email])

  const handleSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    createResetPasswordRequest(email)
      .then(() => {
        setSubmitting(false)

        setMessageSeverity('info')
        setErrorMessage('Password reset request sent, please check your email.')

        setEmail('')
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
        setMessageSeverity('error')
        setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
      })
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Forgot Password"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={messageSeverity}>{errorMessage}</Alert>
      </Stack>}

      <FullPageContentContainer maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} width="100%">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email || ''}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="email"
                />
              </Grid>
            </Grid>

            <Button
              disabled={submitting || submitDisabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {submitting ? 'Submitting...' : 'Submit' }
            </Button>
            <Grid container justifyContent="center">
              <Grid item style={{paddingRight: '1rem'}}>
                <Link href="/app/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
              <Grid item>
                <Link href="/app/signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}
