import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Copyright from '../Copyright'
import RouterLink from '../RouterLink'

function FooterLink(props) {
  const sx = props.sx

  sx.pb = 3

  return (
    <RouterLink
      disableOnClick={props.disableOnClick}
      sx={props.sx}
      textAlign={props.align}
      underline="hover"
      href={props.href}
    >
      {props.children}
    </RouterLink>
  )
}

function FooterColumn(props) {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems={props.align || "left"}
      justifyContent="flex-start"
      item
      xs={3}
    >
      {props.children}
    </Grid>
  )
}

export default function Footer(props) {
  return (
    <Container maxWidth="md" sx={{py: 1, minHeight: "20vh"}}>
      <Box sx={{mt: 5, mb: 5}}>
        <Grid container spacing={2}>
          <FooterColumn>
            <FooterLink sx={{color: props.color}} href="/app/is-it-porn">
              Is It Porn?
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/greentext">
              Greentext Machine
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/storymachine">
              Story Machine
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/poemmachine">
              Poem Machine
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/algorithms">
              All Algorithms
            </FooterLink>
            <FooterLink sx={{color: props.color}} href={'/app/products'}>
              Buy Credits
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterLink sx={{color: props.color}} href="/app/signin">
              Sign In
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/signup">
              Sign Up
            </FooterLink>
            <FooterLink sx={{color: props.color}} href="/app/contact">
              Contact
            </FooterLink>
          </FooterColumn>
          <FooterColumn align="right">
            <FooterLink align="right" disableOnClick sx={{color: props.color}} href="https://facebook.com/Is-It-Porn-1489898601324462/">
              Facebook
            </FooterLink>
          </FooterColumn>
          <FooterColumn align="right">
            <FooterLink align="right" sx={{color: props.color}} href="/app/privacy">
              Privacy Statement
            </FooterLink>
            <FooterLink align="right" sx={{color: props.color}} href="/app/terms">
              Terms of Use
            </FooterLink>
          </FooterColumn>
        </Grid>
      </Box>

      <Copyright color={props.color || "primary"} />
    </Container>
  );
}
