import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import IsItPornHero from './components/IsItPornHero'
import FastStyleTransferHero from './components/FastStyleTransferHero'

import {
  ALGORITHM_SKU_FSTKANAGAWA,
  ALGORITHM_SKU_ISITPORN,
  ALGORITHM_SKU_POEMMACHINE,
  ALGORITHM_SKU_STARRYNIGHT,
  ALGORITHM_SKU_STORYMACHINE,
  ALGORITHM_SKU_THESCREAM
} from '../../consts'

export default function HeroForAlgorithm({
  algorithmSku,
  algorithm,
}) {
  if (algorithm)
    algorithmSku = algorithm.sku

  if (algorithmSku === ALGORITHM_SKU_ISITPORN) {
    return <IsItPornHero />
  }

  if (algorithmSku === ALGORITHM_SKU_FSTKANAGAWA) {
    return <FastStyleTransferHero
      images={[
        '/img/fstransfer-wave-example-1.jpg',
        '/img/fstransfer-wave-example-2.jpg',
        '/img/fstransfer-wave-example-3.jpeg',
      ]}
    />
  }

  if (algorithmSku === ALGORITHM_SKU_THESCREAM) {
    return <FastStyleTransferHero
      images={[
        '/img/fstransfer-scream-example-1.jpg',
        '/img/fstransfer-scream-example-2.jpg',
        '/img/fstransfer-scream-example-3.jpeg',
      ]}
    />
  }

  if (algorithmSku === ALGORITHM_SKU_STARRYNIGHT) {
    return <FastStyleTransferHero
      images={[
        '/img/fstransfer-starry-example-1.jpg',
        '/img/fstransfer-starry-example-2.jpg',
        '/img/fstransfer-starry-example-3.jpeg',
      ]}
    />
  }

  if (algorithmSku === ALGORITHM_SKU_STORYMACHINE) {
    return <FastStyleTransferHero
      images={[
        '/img/storymachine-billgates.jpeg',
        '/img/storymachine-trump-friend-example.jpeg',
        // '/img/storymachine-benshapiro.jpeg',
      ]}
    />
  }

  if (algorithmSku === ALGORITHM_SKU_POEMMACHINE) {
    return <FastStyleTransferHero
      images={[
        '/img/poem-example-1.jpeg',
        '/img/poem-example-3.jpeg',
        // '/img/poem-example-3.jpeg',
      ]}
    />
  }

  if (algorithm && algorithm.coverImageURL) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box maxWidth="600px">
          <img
            src={algorithm.coverImageURL}
            height="auto"
            width="auto"
            alt={`Algorithm ${algorithm.name}`}
            style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
          />
        </Box>
      </Box>
    )
  }

  if (algorithm && algorithm.name) {
    return (
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{mb: 6, mt: 6}}
      >
        {algorithm.name}
      </Typography>
    )
  }

  return null
}
