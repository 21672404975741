import * as React from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ThemeProvider } from '@mui/material/styles'
import Alert from '@mui/material/Alert'

import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import FullPageContentContainer from '../../components/FullPageContentContainer'

import mdTheme from '../../themes/storymachineTheme'
import IsItPornButton from '../../components/IsItPornButton'

import getMyCredits from '../../api/credits/mine'
import { createModelRun } from '../../api/modelRuns/create'
import {addSnackbar} from '../../redux/snackbars'
import HeroForAlgorithm from '../../components/HeroForAlgorithm'
import PaperContainer from '../../components/PaperContainer'
import generateCreditMessage from '../../helpers/credits/generateCreditMessage'
import { ALGORITHM_SKU_STORYMACHINE, PRODUCT_SKU_ISITPORNCREDITS100 } from '../../consts'
import DescriptionBoxForAlgorithm from '../../components/DescriptionBoxForAlgorithm'
import ImpactText from '../../components/ImpactText'

const MAX_LENGTH = 75

export default function NewStoryMachine() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [creditsLoading, setCreditsLoading] = React.useState(true)
  const [remainingCredits, setRemainingCredits] = React.useState(0)
  const [totalFreeCreditsRemaining,setTotalFreeCreditsRemaining] = React.useState(false)
  const [totalStoryMachineRuns, setTotalStoryMachineRuns] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [modelRun, setModelRun] = React.useState(null)

  const [submitting, setSubmitting] = React.useState('')
  const [inputText, setInputText] = React.useState('')

  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  const canRunAlgorithm = (remainingCredits > 0) || (totalFreeCreditsRemaining > 0)
  const creditMessage = generateCreditMessage(totalFreeCreditsRemaining, remainingCredits, currentUserProfile)

  const refreshCredits = () => {
    setCreditsLoading(true)
    return getMyCredits().then(({
      remainingCredits,
      totalFreeCreditsRemaining,
      totalStoryMachineRuns
    }) => {
      setRemainingCredits(remainingCredits)
      setTotalFreeCreditsRemaining(totalFreeCreditsRemaining)
      setTotalStoryMachineRuns(totalStoryMachineRuns)
      setCreditsLoading(false)
    }).catch(err => {
      console.error('Error fetching credits', err)
      setCreditsLoading(false)
      setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingmodelRun', text: 'Error fetching credits.'}))
    })
  }

  React.useEffect(() => {
    refreshCredits()
  }, [dispatch])

  const handleSubmit = () => {
    if (!inputText) return
    // if (!currentUserProfile) return setErrorMessage('Please sign in to use this algorithm.')

  setSubmitting(true)
    return createModelRun({
      inputText,
      algorithmSku: ALGORITHM_SKU_STORYMACHINE
    }).then(modelRun => {
      setSubmitting(false)
      dispatch(addSnackbar({id: 'modelruncreated', text: 'Image generated.'}))
      if (currentUserProfile) {
        navigate(`/app/account/model-runs/${modelRun.uuid}`)
      } else {
        setModelRun(modelRun)
        refreshCredits()
      }
    }).catch(err => {
      console.error(err)
      setSubmitting(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  const renderFormContent = () => {
    if (submitting || creditsLoading) {
      return (
        <PaperContainer background={mdTheme.palette.primary.main}>
          <CircularProgress sx={{my: 2, color: "primary.contrastText"}} />
        </PaperContainer>
      )
    }

    if (modelRun) {
      return (
        <PaperContainer background={mdTheme.palette.primary.main}>
          <IsItPornButton text="Do another" onClick={() => setModelRun(null)} />

          <Box sx={{my: 3}} maxWidth="600px">
            <img
              src={modelRun.imageURL}
              height="auto"
              alt="Preview"
              width="auto"
              style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
            />
          </Box>

          <Typography textAlign="center" fontWeight={600} color="primary.contrastText">{creditMessage}</Typography>

          <IsItPornButton text="Purchase Credits" onClick={() => navigate('/app/products')} />
        </PaperContainer>
      )
    }

    return (
      <PaperContainer background={mdTheme.palette.primary.main}>
        {canRunAlgorithm &&
        <Typography sx={{mb: 2}} textAlign="center" fontWeight={600} color="primary.contrastText">
          Type an idea for a story, for example "Trump and Obama becoming friends".
        </Typography>}
        {canRunAlgorithm &&
        <Box width="100%" maxWidth="500px" sx={{mb: 2}}>

          <TextField
            name="inputText"
            color="primary"
            id="inputText"
            variant="filled"
            value={inputText || ''}
            label="Enter a prompt"
            fullWidth
            InputLabelProps={{style : {color : 'white'} }}
            FormHelperTextProps={{style : {color : 'white'} }}
            helperText={`${(inputText || '').length}/${MAX_LENGTH}`}
            error={inputText.length > MAX_LENGTH}
            sx={{ input: {color: 'white'} }}
            onChange={e => setInputText(e.target.value)}
          />
        </Box>}

        {!canRunAlgorithm &&
        <Box sx={{mb: 2}}>
          <IsItPornButton text="Purchase Credits" onClick={() => navigate(`/app/products/${PRODUCT_SKU_ISITPORNCREDITS100}`)} />
        </Box>}

        {creditMessage &&
        <Typography textAlign="center" fontWeight={600} color="primary.contrastText">
          {creditMessage}
        </Typography>}

        {canRunAlgorithm &&
        <Box sx={{mt: 2}}>
          <IsItPornButton text="Submit" onClick={handleSubmit} />
        </Box>}
      </PaperContainer>
    )
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Helmet defaultTitle="Story Machine" />

      <AppBar>
        <Box display="flex" flexGrow="1" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography
            component="h1"
            variant="h6"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Story Machine
          </Typography>

          <Box sx={{mr: 2, display: {xs: 'none', sm: 'block'}}}>
            <Button onClick={() => navigate('/app/algorithms')} variant="outlined" color="inherit">More Algorithms</Button>
          </Box>
        </Box>
      </AppBar>

      <Toolbar />

      <Box>
        {errorMessage &&
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>}

        <FullPageContentContainer maxWidth="lg">
          <Box flexDirection="row" justifyContent="center" sx={{display: {sm: 'none', xs: 'flex'}}}>
            <Button onClick={() => navigate('/app/algorithms')} variant="outlined">More Algorithms</Button>
          </Box>

          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {creditsLoading ?
            <CircularProgress sx={{my: 2, color: "primary.contrastText"}} />:
            <Box sx={{my: 2}}>
              <ImpactText
                style={{textAlign: 'center', fontSize: '3rem', lineHeight: 1}}
                text={`${totalStoryMachineRuns ? totalStoryMachineRuns.toLocaleString() : '?'} IMAGES GENERATED`}
              />
            </Box>}
          </Box>

          {renderFormContent()}

          <HeroForAlgorithm
            algorithmSku={ALGORITHM_SKU_STORYMACHINE}
          />

          <DescriptionBoxForAlgorithm
            description={
              <Box>
                <Box>
                  Story Machine uses the GPT-3 algorithm provided by OpenAI to generate a story based on a short prompt.
                </Box>
                <Box
                  display="flex"
                  flexGrow="1"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{pt: 1, pr: 1}}
                >
                  <a href="https://twitter.com/storymachine4">
                    <img style={{height: '3rem', marginRight: '1rem'}} src="/img/twitter.svg" alt="Twitter"/>
                  </a>
                  <a href="https://www.facebook.com/Best-of-Story-Machine-107627168496493">
                    <img style={{height: '3rem'}} src="/img/facebook.svg" alt="Facebook"/>
                  </a>
                </Box>
              </Box>
            }
            background={mdTheme.palette.primary.main}
          />
        </FullPageContentContainer>

        <Box sx={{backgroundColor: mdTheme.palette.primary.main, mt: 3}}>
          <Footer color="primary.contrastText" />
        </Box>
      </Box>
    </ThemeProvider>
  );
}