import * as React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'

import fetchTransaction from '../../../../api/transactions/get'
import { addSnackbar } from '../../../../redux/snackbars'
import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'
import centsToDollarString from '../../../../helpers/money/centsToDollarString'

export default function PaymentMethods() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { transactionUuid } = useParams()

  const [errorMessage, setErrorMessage] = React.useState('')
  const [transaction, setTransaction] = React.useState(null)
  const [transactionLoading, setTransactionLoading] = React.useState(true)

  React.useEffect(() => {
    setTransactionLoading(true)
    fetchTransaction(transactionUuid).then(transaction => {
      setTransactionLoading(false)
      setTransaction(transaction)
    }).catch(err => {
      console.error(err)
      setTransactionLoading(false)
      setErrorMessage(err.message)
      dispatch(addSnackbar({id: 'errorcreatingtransaction', text: 'Error fetching transaction.'}))
    })
  }, [transactionUuid])

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/account/transactions')}} underline="hover" color="inherit" href="/app/account/transactions">
            Transactions
          </Link>

          <Typography color="text.primary">
            {transaction &&
            <Link onClick={e => {e.preventDefault(); navigate(`/app/account/transactions/${transaction.uuid}`)}} underline="hover" color="inherit" href={`/app/account/transactions/${transaction.uuid}`}>
              {transaction.uuid}
            </Link>}
            {!transactionLoading && !transaction && 'Unknown'}
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />


      <Container sx={{pt: 3}} maxWidth="md">
        <Typography component="h1" variant="h3">
          Transaction
        </Typography>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Product
          </Typography>
          <Typography>
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {transaction &&
            <Link onClick={e => {e.preventDefault(); navigate(`/app/products/${transaction.product.uuid}`)}} underline="hover" color="inherit" href={`/app/products/${transaction.product.uuid}`}>
              {transaction.product.name}
            </Link>}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Created At
          </Typography>
          <Typography>
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {transaction && new moment(transaction.createdAt).format('llll')}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            Amount
          </Typography>
          <Typography>
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {transaction && centsToDollarString(transaction.amountCents)}
          </Typography>
        </Box>

        <Box sx={{mt: 3}}>
          <Typography component="h3" variant="h5">
            UUID
          </Typography>
          <Typography>
            {transactionLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {transaction && transaction.uuid}
          </Typography>
        </Box>

      </Container>

      <Copyright sx={{ pt: 4 }} />
    </Box>
  )
}
