import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

export default function FastStyleTransferHero({
  images=[]
}) {
  return (
    <Grid container>
      {images.map(image => (
        <Grid
          item
          sm={(images.length === 2) ? 6 : 4}
          xs={12}
          sx={{p: 1}}
        >
          <Card sx={{display: 'flex', overflow: 'hidden'}}>
            <img
              style={{width: '100%'}}
              alt="Algorithm example"
              src={image}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
