import * as React from 'react';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import AppBar from '../../components/AppBar'
import Copyright from '../../components/Copyright'

import mdTheme from '../../themes/iipTheme'

export default function CompleteConfirmEmail() {

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Confirm Email"/>

      <Toolbar />

      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            A confirmation email has been sent, please check your inbox.
          </Typography>
        </Box>

        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
