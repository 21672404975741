import * as React from 'react'

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import HeadingContainer from '../../../../components/HeadingContainer'
import PaymentMethodsList from '../../../../components/PaymentMethodsList'
import Copyright from '../../../../components/Copyright'

export default function PaymentMethods() {
  const [errorMessage, setErrorMessage] = React.useState('')

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Payment Methods
        </Typography>
      </HeadingContainer>

      <Divider />

      <Container sx={{pt: 3}} maxWidth="sm">
        <img
          alt="Powered by Stripe"
          src="/img/stripeblurple.svg"
          style={{maxHeight: '2rem', marginBottom: '1rem'}}
        />

        <PaymentMethodsList
          onErrorMessage={errorMessage => setErrorMessage(errorMessage)}
        />
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </Box>
  )
}
