

import { createTheme } from '@mui/material/styles'

let mdTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#789922',
      contrastText: "#fff"
    },
    secondary: {
      main: '#800000',
    },
  },
})

export default mdTheme
