import * as React from 'react';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import FilterList from '@mui/icons-material/FilterList'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'

import TextField from '@mui/material/TextField'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'
import FilterContainer from '../../../../components/FilterContainer'

import centsToDollarString from '../../../../helpers/money/centsToDollarString'

import searchProducts from '../../../../api/products/search'

let queryTimeout

export default function AdminProductManagement() {
  const navigate = useNavigate()

  const [productsLoading, setProductsLoading] = React.useState(true)
  const [products, setProducts] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [name, setName] = React.useState('')

  const handleSearchProducts = () => {
    searchProducts({name}).then(products => {
      setProducts(products)
      setProductsLoading(false)
    }).catch(err => {
      console.error(err)
      setProductsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    setProductsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchProducts()
    }, 300)
  }, [name])

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Products
        </Typography>

        <Button onClick={() => navigate('/app/admin/products/new')} variant="contained">Add Product</Button>
      </HeadingContainer>

      <Divider />

      <FilterContainer>
        <FilterList sx={{ fontSize: 22 }} />
        <b style={{fontSize: '13px', paddingLeft: '0.15rem', paddingRight: '1rem'}}>Filter</b>

        <TextField
          size="small"
          value={name || ''}
          onChange={e => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />
      </FilterContainer>

      <Divider sx={{mb: 2}} />

      {productsLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!productsLoading &&
      <Container maxWidth={false}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="left"><b>SKU</b></TableCell>
                <TableCell align="left"><b>Price</b></TableCell>
                <TableCell align="left"><b>Purchasable</b></TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {products && products.map((product) => (
                <TableRow
                  key={product.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{product.name || <i>None</i>}</TableCell>
                  <TableCell component="th" scope="row">{product.sku || <i>None</i>}</TableCell>
                  <TableCell component="th" scope="row">{centsToDollarString(product.amountCents) || <i>None</i>}</TableCell>
                  <TableCell component="th" scope="row">{(product.purchasable ? 'True' : 'False') || <i>None</i>}</TableCell>
                  <TableCell align="right">
                    <Link onClick={e => {e.preventDefault() ; navigate(`/app/products/${product.uuid}`)}} underline="none" href={`/app/products/${product.uuid}`}>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{mr: 1}}
                      >
                        Show
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => navigate(`/app/admin/products/${product.uuid}`)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
