import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchModelRuns({userUuid, email, browserFingerprint, page, perPage}={}) {
  const params = new URLSearchParams()

  userUuid && params.set('userUuid', userUuid)
  email && params.set('email', email)
  browserFingerprint && params.set('browserFingerprint', browserFingerprint)
  page && params.set('page', page)
  perPage && params.set('perPage', perPage)

  return axios.get(`${API_BASE_URL}/model-runs?${params.toString()}`).then(response => ({
    modelRuns: response.data,
    totalPages: Number(response.headers['x-total-pages'])
  }))
}
