import * as React from 'react'
import Box from '@mui/material/Box'
import {
  ALGORITHM_SKU_FSTKANAGAWA,
  ALGORITHM_SKU_THESCREAM,
  ALGORITHM_SKU_POEMMACHINE,
  ALGORITHM_SKU_STORYMACHINE,
  ALGORITHM_SKU_STARRYNIGHT
} from '../../consts'

import CreateStyleTransferModelRunForm from './components/CreateStyleTransferModelRunForm'

export default function CreateModelRunForm({
  background,
  algorithmSku,
  setErrorMessage,
}) {
  if (!algorithmSku) return <Box>No algorithm provided.</Box>

  const renderFormContent = () => {
    return (
      <CreateStyleTransferModelRunForm
        background={background}
        algorithmSku={algorithmSku}
        setErrorMessage={setErrorMessage}
      />
    )

    // if (
    //   algorithmSku === ALGORITHM_SKU_FSTKANAGAWA ||
    //   algorithmSku === ALGORITHM_SKU_THESCREAM ||
    //   algorithmSku === ALGORITHM_SKU_STARRYNIGHT
    // ) {
    // }

    // if (algorithmSku === ALGORITHM_SKU_POEMMACHINE || algorithmSku === ALGORITHM_SKU_STORYMACHINE) {

    // }

    // return null
  }

  return (
    <Box maxWidth="lg">
      {renderFormContent()}
    </Box>
  )
}