import * as React from 'react'
import Helmet from 'react-helmet'
import { useNavigate, } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import FullPageContentContainer from '../../components/FullPageContentContainer'
import Footer from '../../components/Footer'

import searchAlgorithms from '../../api/algorithms/search'

import mdTheme from '../../themes/iipTheme'
import sortByImportance from '../../helpers/algorithms/sortByImportance'

export default function ListAlgorithms() {
  const navigate = useNavigate()

  const [algorithmsLoading, setAlgorithmsLoading] = React.useState(true)
  const [algorithms, setAlgorithms] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const handleSearchAlgorithms = () => {
    searchAlgorithms({purchasable: true}).then(algorithms => {
      const algorithmsSorted = sortByImportance(algorithms)
      setAlgorithms(algorithmsSorted)
      setAlgorithmsLoading(false)
    }).catch(err => {
      console.error(err)
      setAlgorithmsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    handleSearchAlgorithms()
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <Helmet title="All Algorithms"/>

      <AppBar title="Algorithms"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Algorithms
        </Typography>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Our full list of algorithms is shown below.
        </Typography>

        {algorithmsLoading &&
        <Box sx={{ display: 'flex', justifyContent:  'center', mt: 4, mb: 4 }}>
          <CircularProgress />
        </Box>}

        {!algorithmsLoading &&
        <Grid container spacing={2} sx={{mt: 4, mb: 4}}>
          {algorithms && algorithms.map((algorithm) => (
            <Grid item key={algorithm.uuid} xs={6} md={4}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                {algorithm.coverImageURL&&
                <CardMedia
                  component="img"
                  sx={{
                    // 16:9
                    // pt: '56.25%',
                  }}
                  image={algorithm.coverImageURL}
                  alt="random"
                />}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {algorithm.name}
                  </Typography>
                  <Typography>
                    {algorithm.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => navigate(`/app/algorithms/${algorithm.uuid}`)}>View</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>}
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  )
}
