import React from 'react'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

import { addSnackbar } from '../../../redux/snackbars'
import createTransaction from '../../../api/transactions/create'
import PaymentMethodsList from '../../../components/PaymentMethodsList'

export default function CreateTransactionButton({
  productUuid,
  setTransaction,
  setErrorMessage,
  selectedPaymentMethod,
  setSelectedPaymentMethod
}) {
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)

  const handleCreateTransaction = () => {
    if (!selectedPaymentMethod) return

    setLoading(true)

    createTransaction({
      productUuid,
      stripePaymentMethodId: selectedPaymentMethod.id
    }).then(async transaction => {
      setLoading(false)
      setTransaction(transaction)
    }).catch(err => {
      console.error(err)
      setLoading(false)
      dispatch(addSnackbar({id: 'errorcreatingtransaction', text: 'Error creating transaction.'}))
    })
  }

  return (
    <Box>
      {!selectedPaymentMethod &&
      <Typography sx={{mb: 3}}>Please select a payment method.</Typography>}

      <PaymentMethodsList
        onErrorMessage={setErrorMessage}
        selectedPaymentMethod={selectedPaymentMethod}
        onPaymentMethodSelected={paymentMethod => setSelectedPaymentMethod((paymentMethod.id === (selectedPaymentMethod && selectedPaymentMethod.id)) ? null : paymentMethod)}
      />

      <Box sx={{mt: 3}}>
        <LoadingButton
          variant="contained"
          onClick={() => handleCreateTransaction()}
          disabled={!selectedPaymentMethod}
          loading={loading}
          fullWidth
        >
          <ShoppingCartIcon sx={{mr: 1}} /> Next
        </LoadingButton>
      </Box>
    </Box>
  )
}
