import { createTheme } from '@mui/material/styles'

let mdTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgb(172,104,45)',
      contrastText: "#fff"
    },
    secondary: {
      main: 'rgb(203,101,209)',
    },
  },
})

export default mdTheme
