import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function getMyTransactions({page, perPage}) {
  const params = new URLSearchParams()

  page && params.set('page', page)
  perPage && params.set('perPage', perPage)

  return axios.get(`${API_BASE_URL}/transactions/mine?${params.toString()}`).then(response => ({
    transactions: response.data,
    totalPages: Number(response.headers['x-total-pages'])
  }))
}
