import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import HeadingContainer from '../../../../components/HeadingContainer'
import Copyright from '../../../../components/Copyright'

import createAlgorithm from '../../../../api/algorithms/create'

import { addSnackbar } from '../../../../redux/snackbars'

export default function AdminEditAlgorithm() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = React.useState('')

  const [name, setName] = React.useState('')
  const [hidden, setHidden] = React.useState(false)
  const [sku, setSku] = React.useState('')
  const [type, setType] = React.useState('')
  const [description, setDescription] = React.useState('')

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [imgSrc, setImgSrc] = React.useState('')
  const [selectedImage, setSelectedImage] = React.useState(null)

  React.useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader()
      reader.readAsDataURL(selectedImage)
      reader.onloadend = () => setImgSrc([reader.result])
    }
  }, [selectedImage])

  const handleSave = () => {
    setSaving(true)

    const formData = new FormData()
    if (selectedImage) formData.append("coverImage", selectedImage, selectedImage.name)
    if (name) formData.append('name', name)
    formData.append('hidden', hidden)
    if (sku) formData.append('sku', sku)
    if (type) formData.append('type', type)
    if (description) formData.append('description', description)

    createAlgorithm(formData).then(() => {
      setSaving(false)
      dispatch(addSnackbar({id: 'adminalgorithmupdated', text: 'Algorithm updated.'}))
      navigate('/app/admin/algorithms')
    }).catch(err => {
      console.error(err)
      setSaving(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  return (
    <div>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/admin/algorithms')}} underline="hover" color="inherit" href="/app/admin/algorithms">
            Algorithms
          </Link>

          <Typography color="text.primary">
            New Algorithm
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />

      <Container maxWidth="sm" sx={{pt: 3}}>
        <FormGroup>
          <Box component="form" noValidate>
            <Grid sx={{ mt: 2 }}>
              <TextField
                name="name"
                required
                fullWidth
                id="name"
                value={name || ''}
                label="Name"
                onChange={e => {setDirty(true); setName(e.target.value)}}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <TextField
                name="sku"
                required
                fullWidth
                id="sku"
                value={sku || ''}
                label="SKU"
                onChange={e => {setDirty(true); setSku(e.target.value)}}
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12}>
                <TextField
                  name="type"
                  required
                  fullWidth
                  id="type"
                  value={type || ''}
                  label="Type"
                  onChange={e => {setDirty(true); setType(e.target.value)}}
                />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 3 }}>
              <TextField
                name="description"
                required
                fullWidth
                id="description"
                multiline
                rows={4}
                value={description || ''}
                label="Description"
                onChange={e => {setDirty(true); setDescription(e.target.value)}}
              />
            </Grid>

            <Grid sx={{ mt: 2 }}>
              <FormControlLabel
                label="Hidden?"
                control={<Checkbox checked={hidden} onChange={e => {setDirty(true); setHidden(e.target.checked)}} />}
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <label htmlFor="contained-button-file">
                  <input
                    style={{display: 'none'}}
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={e =>  {setDirty(true); setSelectedImage(e.target.files[0])}}
                  />

                  <Button fullWidth variant="outlined" component="span" sx={{mb: 3}}>
                    <PhotoCamera sx={{mr: 1}} /> Upload Cover Image
                  </Button>
                </label>

                {imgSrc &&
                <Box sx={{mb: 3}} maxWidth="600px">
                  <img
                    src={imgSrc}
                    height="auto"
                    width="auto"
                    alt="Preview"
                    style={{maxWidth: '100%', maxHeight: '300px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
                  />
                </Box>}

                <Button fullWidth imgSrc onClick={handleSave} disabled={!isDirty || isSaving} variant="contained">Save</Button>
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Container>

      <Copyright sx={{ pt: 4 }} />
    </div>
  )
}
