import iipTheme from '../../../themes/iipTheme'
import fstransferTheme from '../../../themes/fstransferTheme'
import screamTheme from '../../../themes/screamTheme'

import {
  ALGORITHM_SKU_FSTKANAGAWA, ALGORITHM_SKU_ISITPORN, ALGORITHM_SKU_THESCREAM,
} from '../../../consts'

export default function themeForAlgorithm(algorithmSku) {
  if (algorithmSku === ALGORITHM_SKU_FSTKANAGAWA)
    return fstransferTheme
  if (algorithmSku === ALGORITHM_SKU_ISITPORN)
    return iipTheme
  if (algorithmSku === ALGORITHM_SKU_THESCREAM)
    return screamTheme
  return fstransferTheme
}
