import { createTheme } from '@mui/material/styles'

// https://bareynol.github.io/mui-theme-creator/

let mdTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgb(56, 68, 136)',
      contrastText: "#fff"
    },
    secondary: {
      main: 'rgb(203,101,209)',
    },
  },
})

export default mdTheme
