const FilterContainer = props => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 24px'
  }}>
    {props.children}
  </div>
)

export default FilterContainer
