import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import PaymentMethod from '../PaymentMethod'
import NewUserPaymentMethod from '../NewUserPaymentMethod'

import fetchUserPaymentMethods from '../../api/userPaymentMethods/fetchUserPaymentMethods'

import StripeSetupIntentProvider from '../../hoc/StripeSetupIntentProvider'

export default function PaymentMethodsList({
  onErrorMessage,

  // controlled component
  selectedPaymentMethod,
  onPaymentMethodSelected
}) {
  const navigate = useNavigate()
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const [showNewMethodForm, setShowNewMethodForm] = React.useState(false)
  const [userPaymentMethodsLoading, setUserPaymentMethodsLoading] = React.useState(true)
  const [userPaymentMethods, setUserPaymentMethods] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const refreshPaymentMethods = () => {
    setUserPaymentMethodsLoading(true)
    fetchUserPaymentMethods(currentUserProfile.uuid).then(paymentMethods => {
      setUserPaymentMethodsLoading(false)
      setUserPaymentMethods(paymentMethods)
    }).catch(err => {
      console.error(err)
      setUserPaymentMethodsLoading(false)
      setErrorMessage(err.message)
      onErrorMessage && onErrorMessage(err.message)
    })
  }

  React.useEffect(() => {
    if (!currentUserProfile) return navigate('/app/signup')
    refreshPaymentMethods()
  }, [currentUserProfile])

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <Box sx={{ display: 'flex', justifyContent:  'center', mb: 3 }}>
        <Button
          variant="contained"
          color="info"
          onClick={() => setShowNewMethodForm(!showNewMethodForm)}
          fullWidth
        >
          {showNewMethodForm ? 'Cancel' : 'Add new payment method'}
        </Button>
      </Box>

      {showNewMethodForm &&
      <StripeSetupIntentProvider>
        <NewUserPaymentMethod onCreate={() => { refreshPaymentMethods(); setShowNewMethodForm(false) }} />
      </StripeSetupIntentProvider>}

      {userPaymentMethodsLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center', mt: 6 }}>
        <CircularProgress />
      </Box>}

      {!userPaymentMethodsLoading && userPaymentMethods && userPaymentMethods.map(upm => (
      <Box key={upm.id} sx={{ mb: 2 }}>
        <PaymentMethod
          paymentMethod={upm}
          selected={selectedPaymentMethod && (selectedPaymentMethod.id === upm.id)}
          onClick={onPaymentMethodSelected}
          onDelete={refreshPaymentMethods}
        />
      </Box>))}
    </Box>
  )
}
