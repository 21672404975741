import * as React from 'react'
import { useNavigate, } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import FullPageContentContainer from '../../components/FullPageContentContainer'
import Footer from '../../components/Footer'

import searchProducts from '../../api/products/search'

import mdTheme from '../../themes/iipTheme'

export default function ListProducts() {
  const navigate = useNavigate()

  const [productsLoading, setProductsLoading] = React.useState(true)
  const [products, setProducts] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const handleSearchProducts = () => {
    searchProducts({purchasable: true}).then(products => {
      setProducts(products)
      setProductsLoading(false)
    }).catch(err => {
      console.error(err)
      setProductsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    handleSearchProducts()
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title="Products"/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <FullPageContentContainer>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Products
        </Typography>

        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select a product below.
        </Typography>

        {productsLoading &&
        <Box sx={{ display: 'flex', justifyContent:  'center', mt: 4, mb: 4 }}>
          <CircularProgress />
        </Box>}

        {!productsLoading &&
        <Grid container spacing={4} sx={{mt: 4, mb: 4}}>
          {products && products.map((product) => (
            <Grid item key={product.uuid} xs={12} sm={6} md={4}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                {product.coverImageURL&&
                <CardMedia
                  component="img"
                  sx={{
                    // 16:9
                    // pt: '56.25%',
                  }}
                  image={product.coverImageURL}
                  alt="random"
                />}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.name}
                  </Typography>
                  <Typography>
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => navigate(`/app/products/${product.uuid}`)}>View</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>}
      </FullPageContentContainer>

      <Footer />
    </ThemeProvider>
  );
}