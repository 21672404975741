import axios from '../axios'
import { API_BASE_URL } from '../../consts'

import { getBrowserFingerprint } from '../../lib/fingerprintjs'

export function createModelRun(modelRun) {
  return getBrowserFingerprint().then(browserFingerprint => {
    return axios.post(`${API_BASE_URL}/model-runs`, {...modelRun, bf: browserFingerprint}).then(response => (response.data))
  })
}

export function createModelRunFormData(modelRunFormData) {
  return getBrowserFingerprint().then(browserFingerprint => {
    modelRunFormData.append("bf", browserFingerprint)
    return axios.post(`${API_BASE_URL}/model-runs`, modelRunFormData).then(response => (response.data))
  })
}
