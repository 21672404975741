import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'

import AppBar from '../../components/AppBar'
import FullPageContentContainer from '../../components/FullPageContentContainer'
import HeadingContainer from '../../components/HeadingContainer'
import Footer from '../../components/Footer'
import HeroForAlgorithm from '../../components/HeroForAlgorithm'
import CreateModelRunForm from '../../components/CreateModelRunForm'
import DescriptionBoxForAlgorithm from '../../components/DescriptionBoxForAlgorithm'

import getAlgorithm from '../../api/algorithms/get'

import {
  ALGORITHM_SKU_ISITPORN,
  ALGORITHM_SKU_STORYMACHINE,
  ALGORITHM_SKU_POEMMACHINE,
  ALGORITHM_SKU_GREENTEXT
} from '../../consts'

import themeForAlgorithm from './helpers/themeForAlgorithm'

export default function ShowAlgorithm() {
  const { algorithmUuid } = useParams()
  const navigate = useNavigate()

  const [algorithmLoading, setAlgorithmLoading] = React.useState(true)
  const [algorithm, setAlgorithm] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const mdTheme = themeForAlgorithm(algorithm && algorithm.sku)

  const handleReceiveAlgorithm = (algorithm) => {
    setAlgorithm(algorithm)
  }

  React.useEffect(() => {
    getAlgorithm(algorithmUuid).then(algorithm => {
      if (algorithm.sku === ALGORITHM_SKU_ISITPORN) return navigate('/app/is-it-porn')
      if (algorithm.sku === ALGORITHM_SKU_STORYMACHINE) return navigate('/app/storymachine')
      if (algorithm.sku === ALGORITHM_SKU_POEMMACHINE) return navigate('/app/poemmachine')
      if (algorithm.sku === ALGORITHM_SKU_GREENTEXT) return navigate('/app/greentext')

      handleReceiveAlgorithm(algorithm)
      setAlgorithmLoading(false)
    }).catch(err => {
      console.error(err)
      setAlgorithmLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }, [algorithmUuid])

  const title = (algorithmLoading || !algorithm) ? "Show Algorithm" : algorithm.name

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar>
        <Box display="flex" flexGrow="1" flexDirection="row" justifyContent="space-between" alignItems="center">
          {title &&
          <Typography
            component="h1"
            variant="h6"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>}

          <Box sx={{mr: 2, display: {xs: 'none', sm: 'block'}}}>
            <Button onClick={() => navigate('/app/algorithms')} variant="outlined" color="inherit">More Algorithms</Button>
          </Box>
        </Box>
      </AppBar>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/algorithms')}} underline="hover" color="inherit" href="/app/algorithms">
            Algorithms
          </Link>

          <Typography color="text.primary">
            {algorithm && (algorithm.name || <i>Unknown</i>)}
            {algorithmLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {!algorithmLoading && !algorithm && 'Unknown'}
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider />

      {algorithmLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center', mt: 3 }}>
        <CircularProgress />
      </Box>}

      {!algorithmLoading && algorithm &&
      <FullPageContentContainer>
        <CreateModelRunForm
          algorithmSku={algorithm.sku}
          background={mdTheme.palette.primary.main}
          setErrorMessage={setErrorMessage}
        />

        <HeroForAlgorithm algorithm={algorithm} />

        <DescriptionBoxForAlgorithm
          algorithmSku={algorithm.sku}
          description={algorithm.description}
          background={mdTheme.palette.primary.main}
        />

      </FullPageContentContainer>}

      <Footer />
    </ThemeProvider>
  );
}
