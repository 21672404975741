import * as React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'

import Copyright from '../../../../components/Copyright'
import HeadingContainer from '../../../../components/HeadingContainer'

import searchModelRuns from '../../../../api/modelRuns/search'

const PER_PAGE = 10

export default function ListModelRuns() {
  const navigate = useNavigate()

  const [modelRunsLoading, setModelRunsLoading] = React.useState(true)
  const [modelRuns, setModelRuns] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  const currentUserProfile = useSelector((state) => state.currentUserProfile)

  const handleSearchModelRuns = () => {
    setModelRunsLoading(true)
    searchModelRuns({
      userUuid: currentUserProfile && currentUserProfile.uuid,
      page,
      perPage: PER_PAGE
    }).then(({modelRuns, totalPages}) => {
      setModelRuns(modelRuns)
      setTotalPages(totalPages)
      setModelRunsLoading(false)
    }).catch(err => {
      console.error(err)
      setModelRunsLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }

  React.useEffect(() => {
    handleSearchModelRuns()
  }, [page])

  return (
    <Box>
      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Model Runs
        </Typography>
      </HeadingContainer>

      <Divider />

      {modelRunsLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      <Box display="flex" flexDirection="row" justifyContent="center" sx={{p: 2}}>
        <Button onClick={() => navigate('/app/algorithms')} variant="contained" color="primary">All Algorithms</Button>
      </Box>

      {!modelRunsLoading &&
      <Container maxWidth={false}>
        <Pagination
          sx={{mb: 3}}
          count={totalPages}
          page={page}
          onChange={(_, v) => setPage(v)}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"><b>Created At</b></TableCell>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="right"></TableCell>
                {/* <TableCell align="right" /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {modelRuns && modelRuns.map((modelRun) => (
                <TableRow
                  key={modelRun.uuid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{new moment(modelRun.createdAt).format('llll')}</TableCell>
                  <TableCell align="left">{modelRun.algorithm && modelRun.algorithm.name}</TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => navigate(`/app/account/model-runs/${modelRun.uuid}`)}
                    >
                      Show
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </Box>
  )
}
