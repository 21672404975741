import Box from '@mui/material/Box'

const HeadingContainer = props => (
  <Box style={{
    display: 'flex',
    minHeight: '64px',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '24px',
    paddingRight: '24px',
  }}>
    {props.children}
  </Box>
)

export default HeadingContainer
