// order is reversed, items present shown first
const importanceOrder = [
  'isitporn',
  'poemmachine',
  'storymachine',
]

export default function sortByImportance(algorithms) {
  // return algorithms.sort((a,b) => (a.sku > b.sku))
  return algorithms.sort((a,b) => {
    if (importanceOrder.indexOf(a.sku) < importanceOrder.indexOf(b.sku))
      return 1
    if (importanceOrder.indexOf(a.sku) > importanceOrder.indexOf(b.sku))
      return -1
    return 0
  })
}
