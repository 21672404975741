import axios from '../axios'
import { API_BASE_URL } from '../../consts'

export default function searchTransactions({userUuid, showAbandoned, page, perPage}={}) {
  const params = new URLSearchParams()

  userUuid && params.set('userUuid', userUuid)
  showAbandoned && params.set('showAbandoned', true)
  page && params.set('page', page)
  perPage && params.set('perPage', perPage)

  return axios.get(`${API_BASE_URL}/transactions?${params.toString()}`).then(response => ({
    transactions: response.data,
    totalPages: Number(response.headers['x-total-pages'])
  }))
}
