export default function generateCreditMessage(totalFreeCreditsRemaining, remainingCredits, currentUserProfile) {
  if (totalFreeCreditsRemaining > 0) {
    if (remainingCredits > 0)
      return `You have ${totalFreeCreditsRemaining} free credits and ${remainingCredits} paid credits.`

    return `You have ${totalFreeCreditsRemaining} free credits.`
  }

  if (currentUserProfile) {
    if (remainingCredits > 0)
      return `You have ${remainingCredits} credits remaining.`

    return 'You have no credits remaining, please purchase more.'
  } else {
    return 'You have no credits remaining, make an account for free credits.'
  }
}
