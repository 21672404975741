import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { ThemeProvider } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import AppBar from '../../components/AppBar'
import HeadingContainer from '../../components/HeadingContainer'
import PaymentMethod from '../../components/PaymentMethod'
import Copyright from '../../components/Copyright'

import getProduct from '../../api/products/get'

import mdTheme from '../../themes/iipTheme'
import centsToDollarString from '../../helpers/money/centsToDollarString'

import StripePaymentIntentForTransactionProvider from '../../hoc/StripePaymentIntentForTransactionProvider'
import CompleteTransactionButton from './components/CompleteTransactionButton'
import CreateTransactionButton from './components/CreateTransactionButton'

export default function NewTransactionForProduct() {
  const { productUuid } = useParams()
  const navigate = useNavigate()

  const [productLoading, setProductLoading] = React.useState(true)
  const [transaction, setTransaction] = React.useState(null)
  const [product, setProduct] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null)

  const handleReceiveProduct = (product) => {
    setProduct(product)
  }

  React.useEffect(() => {
    getProduct(productUuid).then(product => {
      handleReceiveProduct(product)
      setProductLoading(false)
    }).catch(err => {
      console.error(err)
      setProductLoading(false)
      setErrorMessage((err.response && err.response.data && err.response.data.error) || err.message)
    })
  }, [productUuid])

  return (
    <ThemeProvider theme={mdTheme}>
      <AppBar title={(productLoading || !product) ? "Show Product" : product.name}/>

      <Toolbar />

      {errorMessage &&
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{errorMessage}</Alert>
      </Stack>}

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link onClick={e => {e.preventDefault(); navigate('/app/products')}} underline="hover" color="inherit" href="/app/products">
            Products
          </Link>

          {product &&
          <Link onClick={e => {e.preventDefault(); navigate(`/app/products/${product.uuid}`)}} underline="hover" color="inherit" href={`/app/products/${product.uuid}`}>
            <Typography color="text.primary">
              {product.name || <i>Unknown</i>}
            </Typography>
          </Link>}

          {!product &&
          <Typography color="text.primary">
            {productLoading && <Skeleton animation="wave" style={{minWidth: '200px'}} />}
            {!productLoading && !product && 'Unknown'}
          </Typography>}

          <Typography color="text.primary">
            Purchase
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider sx={{mb: 2}} />

      {productLoading &&
      <Box sx={{ display: 'flex', justifyContent:  'center' }}>
        <CircularProgress />
      </Box>}

      {!productLoading && product &&
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              sx={{mb: 6}}
            >
              {product.name}
            </Typography>

            <Typography variant="h6" align="center" color="text.secondary" paragraph sx={{mb: 6}}>
              Confirm your purchase by registering your card details with Stripe.
            </Typography>

            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              <span style={{whiteSpace: 'pre-wrap'}}>
                {product.description}
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <img
              alt="Powered by Stripe"
              src="/img/stripeblurple.svg"
              style={{maxHeight: '2rem', marginBottom: '1rem'}}
            />

            {!transaction &&
            <CreateTransactionButton
              productUuid={productUuid}
              setTransaction={setTransaction}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />}

            {transaction &&
            <Box>
              <Typography sx={{mb: 3}}>{centsToDollarString(transaction.amountCents)} will be charged to this card.</Typography>

              <PaymentMethod
                selected
                paymentMethod={selectedPaymentMethod}
              />

              <Button
                variant="contained"
                color="info"
                sx={{mt: 3}}
                onClick={() => setTransaction(null)}
                fullWidth
              >
                <ArrowBackIosIcon sx={{mr: 1}} /> Change Card
              </Button>

              <StripePaymentIntentForTransactionProvider transaction={transaction}>
                <CompleteTransactionButton setErrorMessage={setErrorMessage} transaction={transaction}/>
              </StripePaymentIntentForTransactionProvider>
            </Box>}
          </Grid>
        </Grid>
      </Container>}

      <Copyright sx={{ pt: 4 }} />
    </ThemeProvider>
  );
}
