import React from 'react'

import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import fetchMe from '../api/users/fetchMe'
import { setCurrentUserProfile } from '../redux/currentUserProfile'
import { addSnackbar } from '../redux/snackbars'

// Purpose is to navigate user home if de-authed, also
// keep their profile in memory fresh
export default function FetchUserProfile(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUserProfile = useSelector(state => state.currentUserProfile)

  const fetchUserProfile = () => {
    if (currentUserProfile && currentUserProfile.uuid) {
      // if user has session cookie but no profile in state,
      // we get the profile into state here.
      // if user already had profile in state, we're refreshing it. in this case,
      // show a warning on failure.
      fetchMe().then(user => {
        dispatch(setCurrentUserProfile(user))
      }).catch(err => {
        console.error(err)
        if (currentUserProfile && err.response && err.response.data && err.response.data.error === 'You must be signed in.') {
          navigate('/app')
          dispatch(setCurrentUserProfile(null))
          dispatch(addSnackbar({id: 'sessionexpired', text: 'Session expired.'}))
        }
      })
    }
  }

  // run once
  React.useEffect(() => {
    fetchUserProfile()
  }, [])

  return props.children;
}
