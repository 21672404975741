import zxcvbn from 'zxcvbn'

export default function passwordToValidationErrorString(password, confirmPassword) {
  if (!password) return ''

  if (confirmPassword && password !== confirmPassword)
    return 'Confirm password field must match.'

  const result = zxcvbn(password)

  let errorMessage = ''

  result.feedback.suggestions.forEach(suggestion => {
    errorMessage = `${errorMessage}${errorMessage ? ' ' : ''}${suggestion}`
    if (!suggestion.endsWith('.')) errorMessage = `${errorMessage}.`
  })

  if (result.feedback.warning) {
    errorMessage = `${errorMessage} ${result.feedback.warning}`
    if (!result.feedback.warning.endsWith('.')) errorMessage = `${errorMessage}.`
  }

  if (errorMessage) errorMessage = `Password: ${errorMessage}`

  return errorMessage
}
