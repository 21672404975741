import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BrowserRouter, Routes, Route, } from 'react-router-dom'

import FetchUserProfile from './hoc/FetchUserProfile'
import GoogleAnalyticsReporter from './hoc/GoogleAnalyticsReporter'

// top-level
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Contact from './pages/Contact'
import Privacy from './pages/Privacy'
import TermsOfUse from './pages/TermsOfUse'
import ForgotPassword from './pages/ForgotPassword'
import CompletePasswordReset from './pages/CompletePasswordReset'
import ConfirmEmail from './pages/ConfirmEmail'
import CompleteConfirmEmail from './pages/CompleteConfirmEmail'

// account
import Account from './pages/Account'
import Profile from './pages/Account/pages/Profile'
import PaymentMethods from './pages/Account/pages/PaymentMethods'
import AccountShowTransaction from './pages/Account/pages/ShowTransaction'
import AccountListTransactions from './pages/Account/pages/ListTransactions'
import ChangePassword from './pages/Account/pages/ChangePassword'
import ListModelRuns from './pages/Account/pages/ListModelRuns'
import NewModelRun from './pages/Account/pages/NewModelRun'
import ShowModelRun from './pages/Account/pages/ShowModelRun'

// admin
import AdminDashboard from './pages/AdminDashboard'

import AdminUserManagement from './pages/AdminDashboard/pages/AdminUserManagement'
import AdminEditUser from './pages/AdminDashboard/pages/AdminEditUser'

import AdminProductManagement from './pages/AdminDashboard/pages/AdminProductManagement'
import AdminEditProduct from './pages/AdminDashboard/pages/AdminEditProduct'
import AdminNewProduct from './pages/AdminDashboard/pages/AdminNewProduct'
import AdminListModelRuns from './pages/AdminDashboard/pages/AdminListModelRuns'
import AdminShowModelRun from './pages/AdminDashboard/pages/AdminShowModelRun'

import AdminListAlgorithms from './pages/AdminDashboard/pages/AdminListAlgorithms'
import AdminEditAlgorithm from './pages/AdminDashboard/pages/AdminEditAlgorithm'
import AdminNewAlgorithm from './pages/AdminDashboard/pages/AdminNewAlgorithm'

import AdminTransactionManagement from './pages/AdminDashboard/pages/AdminTransactionManagement'
import AdminNewTransaction from './pages/AdminDashboard/pages/AdminNewTransaction'

// algorithms
import NewIsItPorn from './pages/NewIsItPorn'
import NewStoryMachine from './pages/NewStoryMachine'
import NewPoemMachine from './pages/NewPoemMachine'
import NewGreentext from './pages/NewGreentext'
import ListAlgorithms from './pages/ListAlgorithms'
import ShowAlgorithm from './pages/ShowAlgorithm'
import CompleteSSO from './pages/CompleteSSO'

// products
import ListProducts from './pages/ListProducts'
import ShowProduct from './pages/ShowProduct'
import NewTransactionForProduct from './pages/NewTransactionForProduct'
import TransactionComplete from './pages/TransactionComplete'


const Fallback = props => {
  const navigate = useNavigate()
  useEffect(() => {
    // TODO: If not auth, go to /signin
    navigate('/app/algorithms')
  })

  return (
    <main style={{ padding: "1rem" }}>
      <p>There is no page here.</p>
    </main>
  )
}

const Redirect = props => {
  const navigate = useNavigate()
  useEffect(() => navigate(props.path))
  return (
    <main style={{ padding: "1rem" }}>
      <p>There is no page here.</p>
    </main>
  )
}

export default function Router() {
  return (
    <BrowserRouter>
      <FetchUserProfile>
        <GoogleAnalyticsReporter>
          <Routes>
            {/* <Route path="app" element={<Home />} /> */}
            <Route path="app/signin" element={<SignIn />} />
            <Route path="app/signup" element={<SignUp />} />
            <Route path="app/contact" element={<Contact />} />
            <Route path="app/privacy" element={<Privacy />} />
            <Route path="app/terms" element={<TermsOfUse />} />
            <Route path="app/forgot-password" element={<ForgotPassword />} />
            <Route path="app/complete-password-reset" element={<CompletePasswordReset />} />
            <Route path="app/confirm-email" element={<ConfirmEmail />} />
            <Route path="app/complete-sso" element={<CompleteSSO />} />
            <Route path="app/complete-confirm-email" element={<CompleteConfirmEmail />} />

            <Route path="app/is-it-porn" element={<NewIsItPorn />} />
            <Route path="app/storymachine" element={<NewStoryMachine />} />
            <Route path="app/poemmachine" element={<NewPoemMachine />} />
            <Route path="app/greentext" element={<NewGreentext />} />

            <Route path="app/account/*" element={<Account />}>
              <Route path="profile" element={<Profile />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
              <Route path="transactions" element={<AccountListTransactions />} />
              <Route path="transactions/:transactionUuid" element={<AccountShowTransaction />} />
              <Route path="model-runs" element={<ListModelRuns />} />
              <Route path="model-runs/new" element={<NewModelRun />} />
              <Route path="model-runs/:modelRunUuid" element={<ShowModelRun />} />

              <Route path="*" element={<Redirect path="/app/account/model-runs"/>} />
            </Route>

            <Route path="app/admin/*" element={<AdminDashboard />}>
              <Route path="users" element={<AdminUserManagement />} />
              <Route path="users/:userUuid" element={<AdminEditUser />} />

              <Route path="products" element={<AdminProductManagement />} />
              <Route path="products/:productUuid" element={<AdminEditProduct />} />
              <Route path="products/new" element={<AdminNewProduct />} />

              <Route path="algorithms" element={<AdminListAlgorithms />} />
              <Route path="algorithms/:algorithmUuid" element={<AdminEditAlgorithm />} />
              <Route path="algorithms/new" element={<AdminNewAlgorithm />} />

              <Route path="transactions" element={<AdminTransactionManagement />} />
              <Route path="transactions/new" element={<AdminNewTransaction />} />

              <Route path="model-runs" element={<AdminListModelRuns />} />
              <Route path="model-runs/:modelRunUuid" element={<AdminShowModelRun />} />

              {/* <Route path="*" element={<main style={{ padding: "1rem" }}><p>Please select a page.</p></main>} /> */}
              <Route path="*" element={<Redirect path="/app/admin/users"/>} />
            </Route>

            <Route path="app/transactions/:transactionUuid/complete" element={<TransactionComplete />} />

            <Route path="app/algorithms" element={<ListAlgorithms />} />
            <Route path="app/algorithms/:algorithmUuid" element={<ShowAlgorithm />} />

            <Route path="app/products" element={<ListProducts />} />
            <Route path="app/products/:productUuid" element={<ShowProduct />} />
            <Route path="app/products/:productUuid/transactions/new" element={<NewTransactionForProduct />} />
            <Route path="*" element={<Fallback />} />
          </Routes>
        </GoogleAnalyticsReporter>
      </FetchUserProfile>
    </BrowserRouter>
  )
}
